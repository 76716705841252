import moment from 'moment/moment';
import { calculateTimeRange } from '../core/utils';
import { ZOOMLEVELS } from '../chart/structs/zoomlevels';
import {
  BREAK_POINT_MD_DEVICES as GLOBAL_BREAK_POINT_MD_DEVICES,
  BREAK_POINT_XS_DEVICES as GLOBAL_BREAK_POINT_XS_DEVICES,
  BREAK_POINT_SM_DEVICES as GLOBAL_BREAK_POINT_SM_DEVICES,
  MAP_VIEW_CONF as GLOBAL_MAP_VIEW_CONF,
} from '../options';

// exports from global state;
export const BREAK_POINT_MD_DEVICES = GLOBAL_BREAK_POINT_MD_DEVICES;
export const BREAK_POINT_XS_DEVICES = GLOBAL_BREAK_POINT_XS_DEVICES;
export const BREAK_POINT_SM_DEVICES = GLOBAL_BREAK_POINT_SM_DEVICES;
export const MAP_VIEW_CONF = GLOBAL_MAP_VIEW_CONF;

/**
 * For querying the user/device/sensor from the opensensorweb data api
 * @type {string}
 */
export const OSW_DATA_API = process.env.REACT_APP_OSW_DATA_API;

/**
 * Version of the SENSORHUB data api
 * @type {string}
 */
export const OSW_DATA_API_VERSION = process.env.REACT_APP_OSW_DATA_API_VERSION !== undefined
  ? process.env.REACT_APP_OSW_DATA_API_VERSION
  : 'v0';

/**
 * The global current DateTime
 */
export const DEFAULT_DATETIME = moment();

/**
 * The default start interval
 */
export const DEFAULT_INTERVAL = { years: 1 };

/**
 * Describes the overall time range supported by the moduleOsw.
 * @type {[moment(), moment()]}
 */
export const SUPPORTED_TIME_RANGE = calculateTimeRange(DEFAULT_DATETIME, ZOOMLEVELS);
