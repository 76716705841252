/**
 * Created by jacob.mendt@pikobytes.de on 03.09.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import isempty from "lodash.isempty"

/**
 * Keycloak configuration. Falls back to DEFAULT_KEYCLOAK_CONFIGURATION
 * (as long as we develop) if no configuration is set.
 */
export const DEFAULT_KEYCLOAK_CONFIGURATION = {
    "realm": "sensorhub",
    "url": "https://identity-test.pikobytes.de/auth",
    "clientId": "docstore-client",
    "ssl-required": "external",
    "resource": "sensorhub-client",
    "public-client": true,
    "confidential-port": 0
};

const parseConfiguration = (configuration) => {
    try {
        const keycloakConfiguration = JSON.parse(configuration);
        if (!keycloakConfiguration.clientId) {
            keycloakConfiguration.clientId = keycloakConfiguration['resource'];
        }
        if (!keycloakConfiguration.url) {
            keycloakConfiguration.url = keycloakConfiguration['auth-server-url']
                + (keycloakConfiguration['auth-server-url'].endsWith("/")
                    ? ""
                    : "/");
        }
        return keycloakConfiguration;
    } catch (e) {
        console.error(e);
        return DEFAULT_KEYCLOAK_CONFIGURATION;
    }
};

export const getKeycloakConfiguration = () => isempty(process.env.REACT_APP_KEYCLOAK_CONFIGURATION)
    ? DEFAULT_KEYCLOAK_CONFIGURATION
    : parseConfiguration(process.env.REACT_APP_KEYCLOAK_CONFIGURATION);
export default getKeycloakConfiguration;
