/**
 * Created by jacob.mendt@pikobytes.de on 03.09.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
export const MODULE_USER_SET_KEYCLOAK = 'MODULE_USER_SET_KEYCLOAK';

/**
 * @param {Keycloak} newKeycloak
 * @returns {{keycloak: Keycloak, type: string}}
 */
export function setKeycloak(newKeycloak) {
  return {
    type: MODULE_USER_SET_KEYCLOAK,
    keycloak: newKeycloak,
  }
}
