/* eslint-disable import/first,eqeqeq,no-multi-spaces,max-len */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { __RouterContext as RouterContext } from 'react-router';
import {
  Router,
  Route,
} from 'react-router-dom';
import { routerReducer, syncHistoryWithStore } from 'react-router-redux';
import { RouterToUrlQuery } from 'react-url-query';
import { combineReducers, createStore } from 'redux';
import { detect } from 'detect-browser';
import { createHashHistory } from 'history';
import isSupported from '@mapbox/mapbox-gl-supported';

import './_sass/osw.scss';
import './index.scss';
import { Logger } from './logger';
import {
  isServiceWorkerEnabled,
  register,
  unregister,
} from './registerServiceWorker';
import App from './App';
import layoutReducer from './layouts/reducer';
import mainReducer from './core/reducer';
import moduleMapReducer from './components/Map/reducer';
import moduleOswReducer from './structs/reducer';
import moduleOswChartReducer from './chart/reducer';

// Element Id of the app container. The content of this element is replaced with
// the application rendered content.
const APP_CONTAINER_ID = 'app-container';

// give initial logging signal that the page has load
Logger.addBreadcrumb({ message: 'start_logging' });

// fallback behavior in case the user uses firefox
const browserNameIE = detect().name === 'ie';

if (browserNameIE || !isSupported({ failIfMajorPerformanceCaveat: true })) {
  const containerEl = document.getElementById(APP_CONTAINER_ID);
  const defaultMsg = 'Haben Sie anschließend immer noch Probleme wenden Sie sich bitte an <a href="mailto:info@pikobytes.de" title="Contact Email">info@pikobytes.de</a>.';
  const customMsg = browserNameIE
    ? '<h2>Fehlende Browser Unterstüzung</h2><div>Die Anwendung unterstützt keinen Internet Explorer. Bitte wechseln Sie zu einem neueren Browser wie Chrome, Firefox, Edge, Opera oder Safari. '
    : '<h2>Fehlende WebGL Unterstüzung</h2><div>Ihr Browser unterstützt kein WebGL. Bitte wechseln Sie zu einer neueren Browser-Version von Chrome, Firefox, Edge, Opera oder Safari. ';
  const msg = document.createElement('h1');
  msg.innerHTML = `<div class="missing-browser-support">${customMsg}${defaultMsg}</div></div>`;
  containerEl.innerHTML = '';
  containerEl.appendChild(msg);
} else {
  // Add the reducer to your store on the `routing` key and mount the store
  const store = createStore(
    combineReducers({
      chart: moduleOswChartReducer,
      layout: layoutReducer,
      main: mainReducer,
      mapState: moduleMapReducer,
      routing: routerReducer,
      searchOsw: moduleOswReducer,
    }),
  );

  // 2.) Create a hash history for client side single page application routing and bind
  // it with the redux state managementer
  const hashHistory = createHashHistory();
  syncHistoryWithStore(hashHistory, store);

  // 3.) Wrap the basic app components within a router and state manager
  const Main = () => (
    <Provider store={store}>
      <Router history={hashHistory}>
        <RouterToUrlQuery routerContext={RouterContext}>
          <Route excat path="/" component={App} />
        </RouterToUrlQuery>
      </Router>
    </Provider>
  );


  ReactDOM.render(<Main />, document.getElementById(APP_CONTAINER_ID));

  // activate or disable PWA
  if (isServiceWorkerEnabled()) {
    register();
  } else {
    unregister();
  }
}

