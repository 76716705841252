import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Keycloak from 'keycloak-js';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CookieBannerContainer from './components/CookieBanner/CookieBannerContainer';
import Loading from './components/Loading/Loading';
import NavBar from './components/NavBar/NavBar';
import { setKeycloak } from './core/actions';
import { isTouchDevice } from './core/inspector';
import { getKeycloakConfiguration } from './core/keycloak/keycloak'
import UserPage, { SUBPATH_LOGOUT, SUBPATH_USERINFO } from './pages/UserPage/UserPage';
import { BREAK_POINT_MD_DEVICES } from './options';
import { Logger } from './logger';
import './App.scss';

// Load global options
const DEBUG = process.env.REACT_APP_DEBUG === 'true';
const NAVBAR_TITLE = process.env.REACT_APP_NAVBAR_TITLE;
const MODULE_AUTH_ON = process.env.REACT_APP_MODULE_AUTH === 'true';
const MODULE_TOPICMAPS_PM25_ON = process.env.REACT_APP_MODULE_TOPICMAPS_PM25 === 'true';
const MODULE_TOPICMAPS_MOBILE_ON = process.env.REACT_APP_MODULE_TOPICMAPS_MOBILE === 'true';
const PUBLIC_URL = process.env.PUBLIC_URL;

// Definition of the routes
const ROUTE_ABOUT = '/about';
const ROUTE_IMPRESSUM = '/impressum';
const ROUTE_MAIN = '/search';
const ROUTE_MODULE_TOPIC = '/maps/topic';
const ROUTE_MODULE_TOPIC_PM10 = '/maps/topic/pm10';
const ROUTE_MODULE_TOPIC_MOBILE = '/maps/topic/mobile';
const ROUTE_PRIVACY = '/privacy';
const ROUTE_USER = '/user';
const ROUTE_USER_INFO = `${ROUTE_USER}${SUBPATH_USERINFO}`;
const ROUTE_USER_LOGOUT = `${ROUTE_USER}${SUBPATH_LOGOUT}`;

// Routes with a search MainPage
const ROUTES_WITH_SEARCH = [
  ROUTE_MAIN,
  ROUTE_MODULE_TOPIC_PM10,
  ROUTE_MODULE_TOPIC_MOBILE,
];

// default values
const ENUM_TITLES = {
  MAP_DEFAULT: "MAPS",
  MAP_INTERPOLATION: "INTERPOLATION MAP",
  MAP_TOPIC_MOBILE: "MOBILE MAP",
  MAP_TOPIC_PM10: "PM10 MAP",
};

/**
 * Extracts the nav title from the given location pathname.
 * @param {{ pathname: string }}
 * @returns {string}
 */
function getTitleFromLocation({ pathname }) {
  switch (pathname) {
    case ROUTE_MODULE_TOPIC_PM10:
      return ENUM_TITLES.MAP_TOPIC_PM10;
    case ROUTE_MODULE_TOPIC_MOBILE:
      return ENUM_TITLES.MAP_TOPIC_MOBILE;
    default:
      return ENUM_TITLES.MAP_DEFAULT;
  }
}

// presentation components for giving a loading feedback, while performing route loading.
function RouteLoading() {
  return <Loading radius={100} />;
}

// generate the async viewsw
const AboutPage = Loadable({
  loader: () => import(/* webpackChunkName: "aboutView" */'./pages/AboutPage/AboutPage'),
  loading: RouteLoading,
});
const FallbackPage = Loadable({
  loader: () => import(/* webpackChunkName: "fallbackView" */'./pages/FallbackPage/FallbackPage'),
  loading: RouteLoading,
});
const ImpressumPage = Loadable({
  loader: () => import(/* webpackChunkName: "impressumView" */'./pages/ImpressumPage/ImpressumView'),
  loading: RouteLoading,
});
const MainPage = Loadable({
  loader: () => import(/* webpackChunkName: "moduleOsw" */'./pages/MainPage/MainPage'),
  loading: RouteLoading,
});
const ModuleTopicMapsPage = Loadable({
  loader: () => import(/* webpackChunkName: "moduleInterpolation" */'./_modules/moduleTopicMaps'),
  loading: RouteLoading,
});
const PrivacyPage = Loadable({
  loader: () => import(/* webpackChunkName: "privacyView" */'./pages/PrivacyPage/PrivacyPage'),
  loading: RouteLoading,
});

function buildLinksTopicMaps(location) {
  return [
    (MODULE_TOPICMAPS_PM25_ON && window.innerWidth > BREAK_POINT_MD_DEVICES)
      ? {
        name: ENUM_TITLES.MAP_TOPIC_PM10,
        to: { pathname: ROUTE_MODULE_TOPIC_PM10, search: location.search },
      }
      : undefined,
    (MODULE_TOPICMAPS_MOBILE_ON && window.innerWidth > BREAK_POINT_MD_DEVICES)
      ? {
        name: ENUM_TITLES.MAP_TOPIC_MOBILE,
        to: { pathname: ROUTE_MODULE_TOPIC_MOBILE, search: location.search },
      }
      : undefined,
  ].filter(e => e !== undefined)
}

/**
 * Container components for the main application end point.
 */
class App extends Component {
  /**
   * @param {?} props
   */
  constructor(props) {
    super(props);

    // take care that this function is initially triggered, for detecting if it is
    // a touch user
    isTouchDevice();

    // define the state
    this.state = {
      initializeKeycloak: !MODULE_AUTH_ON,
      linkLogo: {
        name: NAVBAR_TITLE,
        to: { pathname: ROUTE_ABOUT, search: this.props.location.search },
      },
      links: this.getLinks(),
    };
  }

  componentDidMount() {
    const { initializeKeycloak } = this.state;
    Logger.addBreadcrumb({ message: 'session_started' });

    if (!initializeKeycloak) {
      this.initializeKeycloak();
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Logger.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
        scope.setExtra(key, errorInfo[key]);
      });

      if (!DEBUG) {
        Logger.captureException(error);
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location, keycloak } = this.props;
    if (
      location.pathname !== prevProps.location.pathname ||
      (keycloak !== undefined && prevProps.keycloak === undefined)
    ) {
      this.setState({
        links: this.getLinks(),
      });
    }
  }

  /**
   * Initialize keycloak and check if user is already logged in.
   */
  initializeKeycloak = () => {
    const keycloak = Keycloak(
      getKeycloakConfiguration(),
    );

    // Use a silent check-sso, to check if the users has already the necessary
    // identification information and if yes refresh the identification
    keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}${PUBLIC_URL}/silent-check-sso.html`,
    })
    .then(
      () => {
        // Dispatch to global state
        this.props.setKeycloak(keycloak);
      },
    ).catch(
      (error) => {
        console.error(error)
        throw new Error('Something went wrong while trying to initialize the authentification');
      }
    );

    keycloak.onTokenExpired = () => {
      keycloak.updateToken(30)
      .error(() => keycloak.login());
    }
  };

  /**
   * Generate the links
   * @returns {*[]}
   */
  getLinks = () => {
    const { keycloak, location } = this.props;

    // Build links
    const linksTopicMaps = buildLinksTopicMaps(location);
    return [
      {
        drawerOrder: 1,
        name: 'SEARCH',
        to: { pathname: ROUTE_MAIN, search: location.search },
      },
      linksTopicMaps.length > 0
        ? {
          drawerOrder: 2,
          icon: <ArrowDropDownIcon />,
          name: getTitleFromLocation(location),
          to: linksTopicMaps,
        }
        : undefined,
      {
        drawerOrder: 3,
        name: 'IMPRINT',
        to: { pathname: ROUTE_IMPRESSUM, search: location.search },
      }, {
        drawerOrder: 4,
        name: 'PRIVACY',
        to: { pathname: ROUTE_PRIVACY, search: location.search },
      },
      // In case the auth is on and keycloak is not initialized or the user is not
      // authenticated
      (MODULE_AUTH_ON && (keycloak === undefined || !keycloak.authenticated))
        ? {
          drawerOrder: 0,
          icon: undefined,
          name: 'Login',
          onClick: () => {
            if (this.props.keycloak !== undefined) {
              this.props.keycloak.login();
            }
          },
        }: undefined,
      // In case the auth is on, keycloak is initialized and the user is logged in
      (MODULE_AUTH_ON && (keycloak !== undefined && keycloak.authenticated))
        ? {
          drawerOrder: 0,
          icon: <AccountCircle/>,
          name: keycloak.tokenParsed.preferred_username,
          to: [
            {
              name: 'UserInfo',
              to: { pathname: ROUTE_USER_INFO, search: location.search },
            },
            {
              name: 'Logout',
              to: { pathname: ROUTE_USER_LOGOUT },
            }
          ]
        }: undefined,
    ].filter(e => e !== undefined);
  };

  /**
   * Render function for the basic layouts of the application.
   */
  render() {
    const {
      linkLogo,
      links,
    } = this.state;
    const {
      history,
      location,
      showMain,
    } = this.props;

    // Check if search view is active
    const isSearchActive = ROUTES_WITH_SEARCH.includes(location.pathname);

    return (
      <div className={`${isSearchActive ? showMain ? 'map' : 'chart' : ''} osw-app`}>
        <NavBar
          breakPoint={BREAK_POINT_MD_DEVICES}
          linkLogo={linkLogo}
          links={links}
        />

        <Switch>
          <Redirect exact from="/" to={ROUTE_MAIN} />

          <Route path={ROUTE_MAIN} component={MainPage} />
          <Route path={ROUTE_ABOUT} component={AboutPage}/>
          <Route path={ROUTE_PRIVACY} component={PrivacyPage} />
          <Route path={ROUTE_IMPRESSUM} component={ImpressumPage} />
          <Route path={ROUTE_USER} component={UserPage} />

          {
            // Route to topic maps
            ((MODULE_TOPICMAPS_MOBILE_ON || MODULE_TOPICMAPS_PM25_ON) && window.innerWidth > BREAK_POINT_MD_DEVICES) && (
              <Route path={ROUTE_MODULE_TOPIC} component={ModuleTopicMapsPage} />
            )
          }
          <Route component={FallbackPage}/>
        </Switch>

        <CookieBannerContainer history={history} detailPage={ROUTE_PRIVACY}/>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  keycloak: PropTypes.instanceOf(Keycloak),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  setKeycloak: PropTypes.func.isRequired,
  showMain: PropTypes.bool.isRequired,
};

// connect the components with the global application state.
export default withRouter(
  connect(
    store => ({
      keycloak: store.main.keycloak,
      showMain: store.layout.showMain,
    }),
    dispatch => ({
      setKeycloak: bindActionCreators(setKeycloak, dispatch),
    }),
  )(App),
);
