import moment from 'moment/moment';
import has from 'lodash.has';

/**
 * Default strings for timezones
 * @enum {string}
 */
export const SUPPORTED_TIMEZONES = {
  BROWSER: 'BROWSER',
  UTC: 'UTC',
  LOCAL: 'LOCAL',
};

/**
 * Function which returns a pseudo utc datetime in iso notation and as GMT, which was
 * shifted by the given offset values. The function expects to get an iso datetime string
 * with GMT time.
 * @param isoString
 * @param offsetInMinutes
 * @returns {string}
 */
export function toPseudoUTC(isoString, offsetInMinutes) {
  const d = moment.utc(isoString);

  if (moment.parseZone(d).utcOffset() !== 0) {
    throw new Error('Function supports only dateTimes with a GMT timezone');
  }

  return d.add(offsetInMinutes, 'minutes').toISOString();
}

/**
 * Returns the given dateTime as ISO string. In case timeZone is GMT the offset suffix
 * is 'Z', in case it is browser the browser offset is set and for all other timezones
 * it returns a local time (no offset)
 * @param {moment|string} dateTime
 * @param {string} timeZone
 * @returns {string}
 */
export function asPseudoISOString(dateTime, timeZone = SUPPORTED_TIMEZONES.UTC) {
  const d = moment.utc(dateTime);

  if (!has(SUPPORTED_TIMEZONES, timeZone)) {
    throw new Error('Given timeZone is not supported');
  }

  // in case we have no offset
  if (moment.parseZone(d).utcOffset() === 0) {
    const dateTimeWithoutOffset = d.toISOString().replace('Z', '');

    return timeZone === SUPPORTED_TIMEZONES.UTC
      ? d.toISOString()
      : timeZone === SUPPORTED_TIMEZONES.BROWSER
        ? d.format('YYYY-MM-DDTHH:mm:ss') + moment().utcOffset((new Date().getTimezoneOffset()) * -1).format('Z')
        : dateTimeWithoutOffset;
  }

  // we perform here a dirty hack. Because moment seems to be missing a functionality
  // to print a utc moment as a local time zone iso string, we add/subtract the offset to
  // the time and return the iso string without the zulu
  const modifiedD = d.add(moment.parseZone(d).utcOffset(), 'minutes');
  return modifiedD.toISOString().replace('Z', '');
}
