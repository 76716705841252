import { Interval, INTERVAL_TYPES } from './utils/interval';
import { RESOLUTION_TYPES } from './zoom';

/**
 * ZoomLevels for displaying data with a lower resolution and a high deep. For the
 * selection of the Zoomleveltype interval it is necessary, that the size of the interval
 * is always divisible regarding the reference unit. For example a year got 12 months.
 * So a interval described in months could be 12 months / 6 months / 4 months / 3 months
 * 2 months or 1 months. If we choose for example 5 months and we would display different
 * timeseries they would have different gridCells and be therefor not comparable.
 *
 * Nevertheless if you want to choose such interval sizes you should extends the
 * TimeseriesGrid.getStartDateTimeForInterval method for returning a proper start DateTime
 * of the GridCell.
 *
 * @type {[Zoomleveltype]}
 */
export const ZOOMLEVELS = [
  {
    // year in seconds * 275
    size: 8672400000, // sum ~275 years
    sizeUnit: 'seconds',
    zoom: 0,
    interval: new Interval({ years: 1 }, INTERVAL_TYPES.YEARLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // year in seconds * 50
    size: 1576800000, // sum ~50 years
    sizeUnit: 'seconds',
    zoom: 1,
    interval: new Interval({ months: 3 }, INTERVAL_TYPES.MONTHLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // year in seconds * 10
    size: 315360000, // sum ~10 years
    sizeUnit: 'seconds',
    zoom: 2,
    interval: new Interval({ months: 1 }, INTERVAL_TYPES.MONTHLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 7 days in seconds
    size: 63072000, // sum ~2 years
    sizeUnit: 'seconds',
    zoom: 3,
    interval: new Interval({ days: 7 }, INTERVAL_TYPES.DAILY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // one day in seconds
    size: 15768000, // sum ~1/2 year
    sizeUnit: 'seconds',
    zoom: 4,
    interval: new Interval({ days: 1 }, INTERVAL_TYPES.DAILY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 12 hours in seconds
    size: 7884000, // sum ~1/4 year
    sizeUnit: 'seconds',
    zoom: 5,
    interval: new Interval({ hours: 12 }, INTERVAL_TYPES.HOURLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 6 hours in seconds
    size: 3942000,
    sizeUnit: 'seconds',
    zoom: 6,
    interval: new Interval({ hours: 6 }, INTERVAL_TYPES.HOURLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 4 hours in seconds
    size: 2419200,
    sizeUnit: 'seconds',
    zoom: 7,
    interval: new Interval({ hours: 4 }, INTERVAL_TYPES.HOURLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 2 hours in seconds
    size: 1209600,
    sizeUnit: 'seconds',
    zoom: 8,
    interval: new Interval({ hours: 2 }, INTERVAL_TYPES.HOURLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 1 hours in seconds
    size: 604800, // sum 7 days
    sizeUnit: 'seconds',
    zoom: 9,
    interval: new Interval({ hours: 1 }, INTERVAL_TYPES.HOURLY),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 15 minutes in seconds
    size: 86400, // sum one day
    sizeUnit: 'seconds',
    zoom: 10,
    interval: new Interval({ minutes: 15 }, INTERVAL_TYPES.MINUTES),
    type: RESOLUTION_TYPES.AGGREGATION,
  }, {
    // 15 minutes in seconds
    size: 21600, // sum one day
    sizeUnit: 'seconds',
    zoom: 11,
    interval: new Interval({ minutes: 1 }, INTERVAL_TYPES.MINUTES),
    type: RESOLUTION_TYPES.AGGREGATION,
  },
];

export default ZOOMLEVELS;
