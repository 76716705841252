/**
 * Created by nicolas.looschen@pikobytes.de on 05.02.2020.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './CookieBanner.scss';

/**
 * Renders a Cookie banner
 *
 * @param {{
 *   handleAcceptClick: Function,
 *   handleDeclineClick: Function,
 *   handleDetailClick: Function,
 * }}
 * @returns {*}
 * @constructor
 */
function CookieBanner({ handleAcceptClick, handleDeclineClick, handleDetailClick }) {
  return <Paper
    id="client-cookie-banner"
    aria-describedby="hygv-client-cookie-banner">
    <div id="client-cookie-banner-header" >
      <InfoIcon />
      <Typography variant="h6">This website uses cookies.</Typography>
    </div>
    <div id="client-cookie-banner-content">
      <Typography variant="body1">We use cookies to ensure that we give you the best experience on our
website by analysing our traffic using Google Analytics. </Typography>
    </div>
    <div id="client-cookie-banner-controls">

      <div id="client-cookie-banner-left-controls">
        <Button variant="contained" color="primary" onClick={handleAcceptClick}>
            Accept
        </Button>
        <Button variant="contained" color="primary" onClick={handleDeclineClick} id="decline-button">
            Decline
        </Button>
      </div>

      <div id="client-cookie-banner-right-controls">
        <Button variant="contained" color="primary" onClick={handleDetailClick}>
            Show Details
        </Button>
      </div>
    </div>

  </Paper>;
}

CookieBanner.propTypes = {
  handleAcceptClick: PropTypes.func,
  handleDeclineClick: PropTypes.func,
  handleDetailClick: PropTypes.func,
};

export default CookieBanner;
