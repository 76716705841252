/**
 * Defines a break point of extra small devices
 * @type {number}
 */
export const BREAK_POINT_XS_DEVICES = 500;

/**
 * Defines a break point of extra small devices
 * @type {number}
 */
export const BREAK_POINT_SM_DEVICES = 768;

/**
 * Defines a break point of mid size devices
 * @type {number}
 */
export const BREAK_POINT_MD_DEVICES = 1088;

/**
 * Defines a break point of large size devices
 * @type {number}
 */
export const BREAK_POINT_LG_DEVICES = 1200;

/**
 * Should the application load within debug mode
 */
export const DEBUG = process.env.REACT_APP_DEBUG === 'true';

/**
 * Configuration parameters for the map
 * @type {{maxBounds: [*], minZoom: number, maxZoom: number}}
 */
export const MAP_VIEW_CONF = {
  maxBounds: [-90, -180, 90, 180],
  minZoom: 0,
  maxZoom: 18,
};

/**
 * For querying the user/device/sensor from the opensensorweb data api
 * @type {string}
 */
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN !== undefined
  ? process.env.REACT_APP_MAPBOX_TOKEN
  : '';

/**
 * @type {number}
 */
export const SCREEN_SIZE_MD = 1024;
