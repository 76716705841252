import { select as d3Select } from 'd3-selection';

/**
 * This parameters is set to true if there was already a touch gesture detected
 * @type {boolean|undefined}
 */
let TOUCH_GESTURE_DETECTED;

/**
 * @type {{NO_TOUCH: string, TOUCH: string}}
 */
export const TOUCH_CLASSNAMES = {
  NO_TOUCH: 'no-touch',
  TOUCH: 'touch',
};

/**
 * The function checks if the device has the capacities for touch gesture.
 * @returns {boolean}
 */
export function browserSupportsTouch() {
  return (
    !!(
      typeof window !== 'undefined' && (
        'ontouchstart' in window || (
          window.DocumentTouch && typeof document !== 'undefined' &&
          document instanceof window.DocumentTouch
        )
      )
    ) || !!(
      typeof navigator !== 'undefined' && (
        navigator.maxTouchPoints || navigator.msMaxTouchPoints
      )
    )
  );
}

/**
 * The function checks if the current session is a touch session. It therefor checks
 * if the device hosting the session has the capacities for touch gesture and if
 * there was already a touch gesture detected.
 * @returns {boolean}
 */
export function isTouchDevice() {
  if (TOUCH_GESTURE_DETECTED === undefined) {
    d3Select('body').classed(TOUCH_CLASSNAMES.TOUCH, false);
    d3Select('body').classed(TOUCH_CLASSNAMES.NO_TOUCH, true);

    window.addEventListener('touchstart', function onFirstTouch() {
      // or set some global variable
      TOUCH_GESTURE_DETECTED = true;

      // update class on body for signaling that the browser is in touch mode
      d3Select('body').classed(TOUCH_CLASSNAMES.TOUCH, true);
      d3Select('body').classed(TOUCH_CLASSNAMES.NO_TOUCH, false);

      // we only need to know once that a human touched the screen, so we
      // can stop listening now
      window.removeEventListener('touchstart', onFirstTouch, false);
    }, false);
  }

  return browserSupportsTouch() && (TOUCH_GESTURE_DETECTED !== undefined &&
    TOUCH_GESTURE_DETECTED === true);
}
