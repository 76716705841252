import { Map } from 'immutable';
import {
  BATCHDOWNLOAD_DELETE_CHART_SETTINGS,
  BATCHDOWNLOAD_UPDATE_CHART_SETTINGS,
} from './actions';

const initialState = {
  chartSettings: Map(),
};

export default function batchDownloadReducer(state = initialState, action) {
  switch (action.type) {
    case BATCHDOWNLOAD_UPDATE_CHART_SETTINGS:
      return Object.assign({}, state, { chartSettings: state.chartSettings.set(action.chartId, action.chartSettings) });
    case BATCHDOWNLOAD_DELETE_CHART_SETTINGS:
      return Object.assign({}, state, { chartSettings: state.chartSettings.delete(action.chartId) });
    default:
      return state;
  }
}
