import {
  LAYOUT_DEFAULT_SET_WIDTH,
  LAYOUT_DFEAULT_SHOW_FEEDBACK,
  LAYOUT_DEFAULT_SHOW_MAIN,
  LAYOUT_DEFAULT_SHOW_SIDE,
} from './actions';

const initialState = {
  feedback: undefined,
  showMain: true,
  widthMain: 0,
  widthScreen: window.innerWidth,
  widthSidebar: 0,
};

const componentViewDefaultReducer = function (state = initialState, action) {
  switch (action.type) {
    case LAYOUT_DEFAULT_SET_WIDTH: {
      return Object.assign({}, state, {
        widthMain: action.widthMain,
        widthScreen: window.innerWidth,
        widthSidebar: action.widthSidebar,
      });
    }

    case LAYOUT_DFEAULT_SHOW_FEEDBACK: {
      return Object.assign({}, state, { feedback: action.feedback });
    }

    case LAYOUT_DEFAULT_SHOW_MAIN: {
      return Object.assign({}, state, { showMain: true });
    }

    case LAYOUT_DEFAULT_SHOW_SIDE: {
      return Object.assign({}, state, { showMain: false });
    }

    default: {
      return state;
    }
  }
};

export default componentViewDefaultReducer;
