/**
 * Calculates for a given set of zoom levels and a reference datetime
 * the time range supported through this date time picker
 *
 * @param {moment} dateTime
 * @param {[ZoomLevelType]} zoomLevels
 * @returns {[moment, moment]} [startDateTime, endDateTime]
 * @private
 */
export function calculateTimeRange(dateTime, zoomLevels) {
  if (zoomLevels === undefined || dateTime === undefined) {
    throw new TypeError('Undefined is not a collection of zoom levels.');
  }

  // get current time and round it up on monthly base
  const endDateTimeRange = dateTime.clone().endOf('month');

  // calculate start date based on the max zoom level size and the end date
  const startDateTimeRange = endDateTimeRange.clone()
    .subtract(zoomLevels[0].size, zoomLevels[0].sizeUnit);

  return [startDateTimeRange, endDateTimeRange];
}

/**
 * Function capitalize a given string.
 * @param {string} string
 * @returns {string}
 */
export function capitalizeString(string) {
  return string.toLowerCase().split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * The function makes sure that a given timeExtent is always within a given timeRange.
 *
 * @param {[moment, moment]} timeRange
 * @param {[moment, moment]} timeExtent
 * @returns {[moment, moment]}
 */
export function fitExtentToTimeRange(timeRange, timeExtent) {
  const [e0, e1] = timeExtent;
  const [r0, r1] = timeRange;

  // in case the timeExtent lies fully outside of the passed timeRange, we return
  // the timeRange
  if (e1.isSameOrBefore(r0) || e0.isSameOrAfter(r1)) {
    return timeRange;
  }

  return [
    e0.isBefore(r0)
      ? r0
      : e0.isSameOrAfter(r0) && e0.isSameOrBefore(r1)
        ? e0
        : r1,
    e1.isBefore(r0)
      ? r0
      : e1.isSameOrAfter(timeRange[0]) && e1.isSameOrBefore(r1)
        ? e1
        : r1,
  ];
}

/**
 * Function returns the value which occurs most within the array.
 * @param {[*]} arr
 * @returns {*|undefined}
 */
export function getMostlyValueFromArray(arr) {
  const r = {};

  if (Array.isArray(arr)) {
    if (arr.length === 0) {
      return undefined;
    }

    arr.forEach((v, i) => {
      if (r[v] === undefined) {
        // Initial object property creation.
        // Create an array for that property.
        r[v] = [i];
      } else {
        // Same occurrences found.
        // Fill the array.
        r[v].push(i);
      }
    });

    // get the value with the most occurrencs
    let valueKey = Object.keys(r)[0];
    let valueCount = 0;
    Object.keys(r).forEach((k) => {
      if (r[k].length > valueCount) {
        valueCount = r[k].length;
        valueKey = k;
      }
    });
    return valueKey;
  }
  return undefined;
}

/**
 * Checks if a given point is within a boundingbox
 * @param {[number]} bbox [xMin, yMin, xMax, yMax]
 * @param {[number]} point [x, y]
 */
export function isPointWithinBoundingbox(bbox, point) {
  return point[0] >= bbox[0] && point[0] <= bbox[2] && point[1] >= bbox[1] && point[1] <= bbox[3];
}

/**
 * Compares two dates but allows a fuzzy around the milliseconds.
 * @param {[moment, moment]} t0
 * @param {[moment, moment]} t1
 * @returns {boolean}
 */
export function isSameNearlyTimePeriod(t0, t1) {
  return t0.length === 2 && t1.length === 2 &&
    t0[0].toISOString().split('.')[0] === t1[0].toISOString().split('.')[0] &&
    t0[1].toISOString().split('.')[0] === t1[1].toISOString().split('.')[0];
}

/**
 * Compares to date and checks if they are the same. A fuzzyFactor allows minimal
 * aberrations from the date.
 * @param {moment} d0
 * @param {moment} d1
 * @param {number} fuzzyFactor The fuzzy factor should be described in millis (Default 0)
 * @return {boolean}
 */
export function isSameDate(d0, d1, fuzzyFactor = 0) {
  const differenceInMillis = Math.abs(d0.diff(d1));
  return differenceInMillis <= fuzzyFactor;
}

/**
 * Prints number with a point seperator every three digits
 * @param {number} x
 * @returns {string}
 */
export function numberWithPointSeperator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

/**
 * @param {string} target
 * @param {string} search
 * @param {string} replacement
 * @returns {string}
 */
export function replaceAll(target, search, replacement) {
  return target.split(search).join(replacement);
}


export function round(value, decimals) {
  // eslint-disable-next-line prefer-template
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

/**
 * Subtracts a given interval description from a given DateTime
 * @param {moment} dateTime
 * @param {{key:value}} intervalDescription
 * @returns {[moment]}
 * @private
 */
export function subtractIntervalFromDateTime(dateTime, intervalDescription) {
  const startDateTime = dateTime.clone();
  Object.keys(intervalDescription).forEach((key) => {
    startDateTime.subtract(intervalDescription[key], key);
  });
  return [startDateTime, dateTime.clone()];
}
