/**
 * Created by jacob.mendt@pikobytes.de on 26.04.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// This token is used if not other information are given
const DEFAULT_TOKEN = 'anonymous';

/**
 * @type {{WRITER: string, READER: string}}
 */
export const USER_ROLES = {};

/**
 * @type {string[]}
 */
export const SUPPORTED_USER_ROLES = Object.values(USER_ROLES);

/**
 * @type {{PASSWORD: string, USERNAME: string}}
 */
const STORAGE_KEYS = {
  PASSWORD: 'sh-basic-auth-password',
  USERNAME: 'sh-basic-auth-username',
};


/**
 * @param {string|undefined} token
 * @returns {{Authorization: string}}
 */
export function getAuthHeader(token) {
  return token === undefined
    ? { Authorization: `Token ${DEFAULT_TOKEN}`}
    : { Authorization: `Bearer ${token}` };
}

/**
 * Getter function for extracting the keycloak token
 * @param {Keycloak|undefined} keycloak
 * @returns {string|undefined}
 */
export function getKeycloakToken(keycloak) {
  return keycloak !== undefined && keycloak.authenticated
    ? keycloak.token
    : undefined;
}

/**
 * @returns {{ password: string, username: string }|undefined}
 */
export function getCredentials() {
  const password = window.sessionStorage.getItem(STORAGE_KEYS.PASSWORD);
  const username = window.sessionStorage.getItem(STORAGE_KEYS.USERNAME);
  const credentials = (password === null || password.length === 0) ||
  (username === null || username.length === 0)
    ? undefined
    : { password: password, username: username };

  return credentials;
}

/**
 * Checks if the given user is correctly authenticated
 * @param {Keycloak|undefined} keycloak
 * @returns {*|boolean}
 */
export function isAuthenticated(keycloak) {
  return keycloak !== undefined && keycloak.authenticated;
}
