/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Created by jacob.mendt@pikobytes.de on 26.04.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router'
import { NavLink, Link, withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NavDropDown from '../NavDropDown/NavDropDown';
import './NavBar.scss';

// Load global options
const FILENAME_LOGO = process.env.REACT_APP_NAVBAR_LOGO;
const DISPLAY_PREVIEW_BADGE = process.env.REACT_APP_NAVBAR_PREVIEW === 'true';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexDirection: "row",
    display: "flex"
  },
  menuButton: {
    marginTop: 10,
    marginRight: -12,
  },
  logo: {
    background: `url(${
      require(`../../_images/${FILENAME_LOGO.length > 1 ? FILENAME_LOGO : 'oswLogo.svg'}`)
    }) no-repeat center center;`,
  },
  logoSbs: {
    transform: "scale(1.5)",
    background: `url(${
      require(`../../_images/Sbs_Logo.svg`)
    }) no-repeat center center;`,
  },
  sbsNavLink: {
    width: 345,
    paddingLeft: 56,

  },
  listItemIcon: {
    marginRight: 0,
  },
};

class NavBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toggleMenu: false,
      width: window.innerWidth,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  /**
   * Called when a resize event occures. By using an Arrow Function we do not
   * have to assign this when using as event listener.
   */
  handleResize = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * Closes the Menu. Only works if the width is smaller then the breakPoint.
   */
  handleCloseMenu = () => {
    const { width } = this.state;
    const { breakPoint } = this.props;

    if (width < breakPoint) {
      this.setState({ toggleMenu: false });
    }
  };

  /**
   * Toggles the Menu. Only works if the width is smaller then the breakPoint.
   */
  handleToggleMenu = () => {
    const { toggleMenu, width } = this.state;
    const { breakPoint } = this.props;

    if (width < breakPoint) {
      this.setState({ toggleMenu: ! toggleMenu });
    }
  };

  renderNavLinksDrawer() {
    const { classes, links } = this.props;

    // make sure that icon fields got placed first. Currently the only icon field
    // is a login field and should be at the top of the drawer list. We clone the list
    // for preventing side effects.
    const linksSorted = [...links].sort(
      (link1, link2) => {
        return link1.drawerOrder - link2.drawerOrder;
      },
    );

    return (
      <div className="sh-navitem-drawer">
        <List>
          {
            linksSorted.map(
              (firstLink, firstIndex) => {
                if (Array.isArray(firstLink.to)) {
                  return (
                    <React.Fragment key={firstIndex}>
                      <ListItem button>
                        <ListItemText primary={firstLink.name.toUpperCase()} className="sh-text" />
                      </ListItem>
                      <Divider />
                      {
                        firstLink.to.map(
                          (secondLink, secondIndex) => {
                            return (
                              <NavLink
                                key={secondIndex}
                                className="sh-navlink second"
                                activeClassName="is-active"
                                to={secondLink.to}
                              >
                                <ListItem button>
                                  <ListItemText primary={secondLink.name.toUpperCase()} className="sh-text" />
                                </ListItem>
                              </NavLink>
                            );
                          },
                        )
                      }
                    </React.Fragment>
                  )
                }

                if (!Array.isArray(firstLink.to) && firstLink.to !== undefined) {
                  return (
                    <NavLink
                      key={firstIndex}
                      className="sh-navlink"
                      activeClassName="is-active"
                      to={firstLink.to}
                    >
                      <ListItem button>
                        {
                          // in case there is an icon
                          firstLink.icon !== undefined && (
                            <ListItemIcon className={classes.listItemIcon}>{firstLink.icon}</ListItemIcon>
                          )
                        }
                        <ListItemText primary={firstLink.name.toUpperCase()} className="sh-text" />
                      </ListItem>
                    </NavLink>
                  );
                }

                if (firstLink.onClick !== undefined) {
                  return (
                    <a href="#" className="sh-navlink" key={firstIndex}>
                      <ListItem button onClick={firstLink.onClick}>
                        {
                          // in case there is an icon
                          firstLink.icon !== undefined && (
                            <ListItemIcon className={classes.listItemIcon}>{firstLink.icon}</ListItemIcon>
                          )
                        }
                        <ListItemText primary={firstLink.name.toUpperCase()} className="sh-text" />
                      </ListItem>
                    </a>
                  )
                }

                return '';
              },
            )
          }
        </List>
      </div>
    );
  }

  renderNavLinks() {
    const { links, location } = this.props;
    return (
      <React.Fragment>
        {
          links.map(
            ({ name, to, icon, onClick }, index) => {
              if (Array.isArray(to)) {
                return (
                  <NavDropDown
                    key={index}
                    linkName={name}
                    linkIndex={index}
                    links={to}
                    location={location}
                    icon={icon}
                  />
                )
              }

              // Check if we use a icon button or a normal button
              const CustomButton = icon === undefined ? Button : IconButton;

              if (to !== undefined) {
                return (
                  <CustomButton
                    key={index}
                    component={Link}
                    className={classnames('sh-navlink', !!matchPath(to.pathname, location.pathname) ? 'is-active' : '')}
                    to={to}>
                    {icon !== undefined && icon}
                    {icon !== undefined && ' '}
                    {name.toUpperCase()}
                  </CustomButton>
                );
              }

              if (onClick !== undefined) {
                return (
                  <CustomButton
                    key={index}
                    className={"sh-navlink"}
                    onClick={onClick}>
                    {icon !== undefined && icon}
                    {icon !== undefined && ' '}
                    {name.toUpperCase()}
                  </CustomButton>
                )
              }

              return '';
            },
          )
        }
      </React.Fragment>
    );
  }

  render() {
    const { toggleMenu, width } = this.state;
    const { breakPoint, classes, linkLogo } = this.props;

    return (
      <div className={classnames(classes.root, 'sh-header')}>
        <AppBar className="sh-navbar" position="static">
          <Toolbar>
            <div className={classnames(classes.grow, 'sh-navitem-logo')}>
              <a className={classnames("header-logo", classes.sbsNavLink)} href="https://www.sbs.sachsen.de/" target="_blank">
                <h1 className={classes.logoSbs}>linkLogo.name</h1>
              </a>
            </div>
            {
              // If the app width is small than the breakPoint display a burger menu
              // instead of the normal navlinks
              width >= breakPoint
                ? (
                  this.renderNavLinks()
                ) : (
                  <IconButton
                    aria-label="Toggle Menu"
                    className={classnames(classes.menuButton, 'sh-navitem-burger')}
                    color="inherit"
                    onClick={this.handleToggleMenu}
                  >
                    {
                      toggleMenu
                        ? (<ClearIcon />)
                        : (<MenuIcon />)
                    }
                  </IconButton>
                )
            }
            <Drawer
              className="sh-navitem-drawer"
              anchor="top"
              open={width < breakPoint && toggleMenu}
              onClose={this.handleCloseMenu}
            >
              <div
                tabIndex={0}
                role="button"
                onClick={this.handleCloseMenu}
                onKeyDown={this.handleCloseMenu}
              >
                { this.renderNavLinksDrawer() }
              </div>
            </Drawer>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

NavBar.propTypes = {
  breakPoint: PropTypes.number,
  classes: PropTypes.object.isRequired,
  linkLogo: PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      drawerOrder: PropTypes.number,
      name: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([
        PropTypes.shape({
          pathname: PropTypes.string,
          search: PropTypes.string,
        }),
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            to: PropTypes.shape({
              pathname: PropTypes.string,
              search: PropTypes.string,
            }),
            icon: PropTypes.element,
            onClick: PropTypes.func,
          }),
        ),
      ]),
      icon: PropTypes.element,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

NavBar.defaultProps = {
  breakPoint: 1088,
};

export default withRouter(withStyles(styles)(NavBar));
