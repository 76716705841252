/**
 * Created by jacob.mendt@pikobytes.de on 31.08.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Returns the JSX content for is not an admin.
 * @param {{
 *   className: string,
 *   text: string,
 * }} props
 * @returns {XML}
 */
export function ViewNoRights({ text }) {
  return (
    <div className="sh-container not-authorized-fallback">
      <p>You are not authorized to view this page. {text}</p>
    </div>
  );
}

ViewNoRights.defaultProps = {
  text: 'Please login again or contact the administrator of the application.'
};

ViewNoRights.propTypes = {
  text: PropTypes.string,
};

export default ViewNoRights;
