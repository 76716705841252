import moment from 'moment/moment';
import isUndefined from 'lodash.isundefined';
import { DEFAULT_DATETIME } from '../../structs/options';

/**
 * Calculate a timeRange which excatly fits the given timeRanges from the chartStorage.
 * @param {Map} chartsStorage
 * @returns {[moment.utc, moment.utc]}
 */
export function calculateTimeRangeFit(chartsStorage) {
  const defaultTimeRange = [
    DEFAULT_DATETIME.clone().subtract(1, 'years'),
    DEFAULT_DATETIME.clone(),
  ];

  let minTimeRange = null;
  let maxTimeRange = DEFAULT_DATETIME.clone();
  chartsStorage.keySeq().toArray().forEach((key) => {
    const dataFeature = /** @typedef {DataFeatureType} */ chartsStorage.get(key);
    const timeRange = dataFeature.getTimeInterval();

    if (minTimeRange === null || timeRange[0].unix() < minTimeRange.unix()) {
      minTimeRange = timeRange[0].clone();
    }

    if (maxTimeRange === null || timeRange[1].unix() > maxTimeRange.unix()) {
      maxTimeRange = timeRange[1].clone();
    }
  });

  if (minTimeRange !== null && maxTimeRange !== null) {
    return [minTimeRange, maxTimeRange];
  }

  return defaultTimeRange;
}


/**
 * @param {[moment, moment]} timeExtent
 * @param {number} sourceId
 * @param {string} eventSourceId
 * @returns {[moment, moment]}
 */
export function createTimeExtentObj(timeExtent, sourceId, eventSourceId) {
  const tE = timeExtent;
  tE.source = sourceId;
  tE.eventSource = eventSourceId;
  return tE;
}

/**
 * Extract from a given chartsStorage a timeExtent, which is a union over
 * all single timeExtent from the contained charts.
 * @param {Immutable.Map} chartsStorage
 * @returns {[moment, moment]|undefined}
 */
export function extractTimeExtentFromCharts(chartsStorage) {
  let minTime;
  let maxTime;
  // iterate keys
  chartsStorage.keySeq().forEach((k) => {
    const dataFeature = /** @typedef {DataFeatureType} */ chartsStorage.get(k);
    const d = dataFeature.getTimeInterval();

    if (!isUndefined(d)) {
      if (isUndefined(minTime) || d[0].diff(maxTime) < 0) {
        minTime = d[0];
      }

      if (isUndefined(maxTime) || d[1].diff(maxTime) > 0) {
        maxTime = d[1];
      }
    }
  });

  return isUndefined(minTime) || isUndefined(maxTime)
    ? undefined
    : [minTime, maxTime];
}

/**
 * Checks if a given timeExtent matches the charts configuration contained within
 * the chartsInView property and if not extend it, with the extendSize property.
 *
 * @param {Map} chartsStorage
 * @param {[moment, moment]} timeExtent
 * @param {*} extendSize
 * @param {}
 * @returns {[moment, moment]}
 */
export function fitTimeExtentToCharts(chartsStorage, timeExtent, extendSize = { years: 1 }) {
  if (chartsStorage.size < 1) {
    return timeExtent;
  }

  const unionTimeExtent = extractTimeExtentFromCharts(chartsStorage);

  // in case a timeInterval could not been parsed return default timeExtent
  if (isUndefined(unionTimeExtent)) {
    return timeExtent;
  }

  // check if maxDateTime is smaller than the given minDateTime of the timeExtent
  // and if yes fit the timeExtent to containing the maxDateTime.
  let minDateTime = timeExtent[0].clone();
  while (unionTimeExtent[1].diff(minDateTime) <= 0) {
    minDateTime = minDateTime.subtract(extendSize);
  }

  return [minDateTime, timeExtent[1].clone()];
}

/**
 * The function checks if a given dateTime lies within a timeRange and if yes
 * splits the timeRange at the point of the dateTime and return two timeRanges.
 *
 * @param {[string, string]} timeRange - e.g. ['2019-02-04T21:00:00.000Z', '2019-07-11T16:00:00.000Z']
 * @param {string} dateTime - e.g. '2019-02-04T21:00:00.000Z'
 * @returns {[[string, string]]}
 */
export function splitTimeRange(timeRange, dateTime) {
  const tr0 = moment(timeRange[0]);
  const tr1 = moment(timeRange[1]);
  const dt = moment(dateTime);

  // check if the dateTime lies within the timeRange
  if (dt.isAfter(tr0) && dt.isBefore(tr1)) {
    return [
      [timeRange[0], dateTime],
      [dateTime, timeRange[1]],
    ];
  }

  // default is we return the timeRange
  return [timeRange];
}
