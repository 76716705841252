/* eslint-disable max-len,jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactGa from 'react-ga';

/**
 * Disables the GA
 */
export function disableGA() {
  setGaCookie(true);
  removeGaCookies();
  ReactGa.ga('remove');
}

/**
 * set the cookie to allow ga
 */
export function enableGA() {
  setGaCookie(false);
}

/**
 * Returns an string which signals the google analytics library to be disabled.
 * @returns {string}
 */
export function getGaDisableStr() {
  return `ga-disable-${process.env.REACT_APP_GA_TOKEN}`;
}

/**
 * initializes a ga tracker
 */
export function initializeTracker() {
  ReactGa.initialize(process.env.REACT_APP_GA_TOKEN, {
    gaOptions: {
      anonymizeIp: true,
      siteSpeedSampleRate: 100,
    },
  });
  ReactGa.ga('send', 'pageview');
}

/**
 * checks whether a cookie is set to either enable or disable ga
 * @returns {boolean}
 */
export function isGaCookieSet() {
  return document.cookie.indexOf(`${getGaDisableStr()}`) > -1;
}

/**
 * Checks if google analytics is enabled/disabled. Returns true if google analytics
 * is disabled.
 * @returns {boolean}
 */
export function isGADisabled() {
  return document.cookie.indexOf(`${getGaDisableStr()}=true`) !== -1;
}

/**
 * sets the google analytics cookie
 * @param active - specifies whether ga should be on or off
 */
export function setGaCookie(active = false) {
  document.cookie = `${getGaDisableStr()}=${active ? 'true' : 'false'}; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
}

/**
 * removes the cookies used for ga by setting their expiration date to a date in the past
 */
function removeGaCookies() {
  const gaCookies = ['_ga', '_gat', '_gid'];
  const cookiesAsKeyValue = document.cookie.split(';').map(a => a.trim().split('='));

  cookiesAsKeyValue.forEach((b) => {
    if (gaCookies.indexOf(b[0]) !== -1) {
      document.cookie = `${b[0]}= ;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  });
}

/**
 * enable the cookie and activate the tracker
 */
function onGiveConsentClick() {
  enableGA();
  initializeTracker();
}

/**
 * Page containing the privacy information.
 */
export const PrivacyPage = () => <div className="sh-container privacy">
  <div>
    <h3 className="title is-3">Datenschutzerklärung</h3>
    <p>PIKOBYTES nimmt den Schutz personenbezogener Daten sehr ernst. Wir möchten, dass Sie wissen, wann wir welche Daten speichern und wie wir sie verwenden.</p>
    <br/>
    <p>PIKOBYTES ist ein privatrechtliches Unternehmen und unterliegt den Bestimmungen des Bundesdatenschutzgesetzes (BDSG), des Telemediengesetzes (TMG) und der europäischen Datenschutzgrundverordnung (DSGVO). Wir haben die notwendigen technischen und organisatorischen Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz von uns und unseren externen Dienstleistern beachtet werden.</p>
    <br/>
    <h4 className="title is-4">Datenerfassung auf unserer Webseite</h4>
    <p>Personenbezogene Daten können genutzt werden um Ihre Identität zu erfahren. Dazu zählen zum Beispiel Ihr richtiger Name und Ihre Postanschrift und Ihre IP-Adresse.</p>
    <br/>
    <p>Sie können unser Online-Angebot grundsätzlich ohne Offenlegung Ihrer Identität nutzen. In Verbindung mit Ihrem Zugriff auf unsere Seiten werden auf unseren Servern Daten für Sicherungszwecke gespeichert, die möglicherweise eine Identifizierung zulassen (zum Beispiel IP-Adresse, genutzer Internetbrowser, aufgetretene Fehlermeldungen, Datum, Uhrzeit sowie Informationen den genutzen Funktionen und Inhalten). Es findet jedoch keine personenbezogene Verwertung dieser Daten statt.</p>
    <br/>
    <p>Wir erheben diese Daten um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Einen Teil der Daten wird in anonymisierter Form auch zur Analyse des Nutzerverhaltens verwendet.</p>
    <br/>
    <p>Wenn Sie uns personenbezogene Daten überlassen haben, können Sie diese jederzeit wieder löschen, soweit wir nicht durch gesetzliche Aufbewahrungspflichten gehalten sind, diese Daten für einen bestimmten Zeitraum zu speichern. In diesem Fall werden wir die Daten unverzüglich nach Ablauf der Speicherfrist löschen. Weiterhin können Sie falsche erfasste Daten selbstverständlich jederzeit korrigieren lassen.</p>
    <br/>
    <p>Ihr Recht auf Widerruf und Korrektur können Sie gegenüber folgender Anschrift geltend machen:</p>
    <br/>
    <p>PIKOBYTES GmbH<br/>Schnorrstraße 70<br/>01069 Dresden<br/>info (at) pikobytes (dot) de</p>
    <br/>
    <h4 className="title is-4">Dienste von Drittanbietern</h4>
    <p>Mit dem Besuch unserer Website nutzen Sie Dienste von Drittanbietern. Wenn Sie damit nicht einverstanden sind, können Sie dies durch eine entsprechende Konfiguration Ihres Internetbrowsers verhindern. In einigen Fällen ist auch ein Wiederspruch (opt-out) für bestimmte Dienste möglich.</p>
    <br/>
    <h4 className="title is-4">Google Analytics</h4>
    <p>Diese Website nutzt den Webanalysedienst Google Analytics. Mit der Nutzung von Google Analytics optimieren wir die Gestaltung und Auffindbarkeit unseres Webangebotes. Der Anbieter von Google Analytics ist Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
    <br/>
    <p>Google Analytics erfasst Daten über Ihre Benutzung dieser Website in Form von Cookies. Diese Cookies werden in der Regel an einen Server von Google in die USA übertragen und dort gespeichert. Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
    <br/>
    <p>Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.</p>
    <br/>
    <p><strong>Deaktivierung von Google Analytics</strong></p>
    <br/>
    <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de.">https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
    <br/>
    <p>Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: <a onClick={disableGA}>Google Analytics deaktivieren</a>. Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a></p>
    <br/>
    <p>Sie können die Erfassung Ihrer Daten durch Google Analytics erlauben, indem Sie auf folgenden Link klicken. Es wird ein Opt-In-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website erlaubt: <a onClick={onGiveConsentClick}>Google Analytics aktivieren</a></p>
    <br/>
    <h4 className="title is-4">MAPBOX</h4>
    <p>Diese Seite nutzt die Dienste Mapbox Maps zur Darstellung interaktiver Karten und Mapbox Search für Adressabfragen. Die Nutzung von Mapbox-Diensten ist notwendig, um Landkarten auf dieser Webseite anzuzeigen und nach Ortsnamen zu suchen. Der Anbieter beider Dienste ist Mapbox Inc., 1509 16th St NW, Second Floor, Washington, DC 20036.</p>
    <br/>
    <p>Durch die Nutzung der Mapbox Maps und Mapbox Search können Informationen über die Benutzung dieser Website einschließlich Ihrer IP-Adresse an Mapbox in den USA übertragen werden. Wenn Sie eine der Kartenansichten öffnen, baut Ihr Browser eine direkte Verbindung mit den Servern von Mapbox auf. Der Karteninhalt wird von Mapbox direkt an Ihren Browser übermittelt und von diesem in die Website eingebunden. Daher haben wir keinen Einfluss auf den Umfang der auf diesem Wege von Mapbox erhobenen Daten.</p>
    <br/>
    <p>Weitere Informationen über den Zweck und den Umfang der Datenerhebung sowie die weitere Verarbeitung und Nutzung der Daten durch Mapbox, Ihre diesbezüglichen Rechte sowie Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Mapbox unter <a href="https://www.mapbox.com/privacy/">https://www.mapbox.com/privacy/</a>. Mapbox speichert und analysiert unter anderem Ihre IP-Adresse, Datum und Uhrzeit der Nutzung sowie die Adresse der besuchten Seite. Mapbox behält sich vor, in einzelnen Fällen auch bestimmte Nutzer zu tracken. Diese Informationen verwendet Mapbox nach eigenen Angaben ausschließlich zur Verbesserung des zur Verfügung gestellten Dienstes für Diagnose- und Analysezwecke. Personenbezogene Daten gibt Mapbox nicht an Dritte weiter.</p>
    <br/>
    <p>Mapbox ist unter den Bedingungen des „EU – U.S. Privacy Shield Frameworks“ zertifiziert und unterliegt dem „U.S. – Swiss Privacy“ Framework.</p>
    <br/>
    <p><strong>Deaktivierung der Mapbox-Dienste</strong></p>
    <br/>
    <p>Wenn Sie nicht möchten, dass Mapbox über unseren Internetauftritt Daten über Sie erhebt, verarbeitet oder nutzt, können Sie in Ihren Browsereinstellungen JavaScript deaktivieren. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall viele Funktionen auf dieser Seite nicht mehr nutzen können.</p>
  </div>
</div>;

export default React.memo(PrivacyPage);
