/**
 * Created by jacob.mendt@pikobytes.de on 17.04.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from 'react';
import { matchPath } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper/Popper';
import Grow from '@material-ui/core/Grow/Grow';
import Paper from '@material-ui/core/Paper/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

/**
 * NavDropDown component.
 */
export default class NavDropDown extends Component {
  state = {
    anchorEl: React.createRef(),
    isToggled: false,
  };

  /**
   * Handler for closing behavior
   * @param {*} event
   */
  handleCloseDropdown = (event) => {
    const { anchorEl } = this.state;

    if (anchorEl.current.contains(event.target)) {
      return;
    }

    this.setState({ isToggled: false });
  };

  /**
   * Handler for toggling behavior
   */
  handleToggleDropdown = () => {
    const { isToggled } = this.state;
    this.setState({ isToggled: !isToggled });
  };


  render() {
    const { anchorEl, isToggled } = this.state;
    const {
      className,
      icon,
      linkName,
      linkIndex,
      links,
      location,
    } = this.props;

    return (
      <div key={linkIndex} className={classnames('sh-nav-dropdown', className)}>
        <Button
          buttonRef={anchorEl}
          aria-owns={isToggled ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          className="sh-navlink"
          onClick={this.handleToggleDropdown}
        >{linkName} { icon !== undefined && icon}</Button>
        <Popper open={isToggled} anchorEl={anchorEl.current} transition disablePortal className="tooltip">
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                  <MenuList>
                    {
                      links.map(
                        ({ name, to }, index) => {
                          return (
                            <MenuItem
                              key={`${linkName}_${index}`}
                              component={Link}
                              className={classnames(!!matchPath(to.pathname, location.pathname) ? 'is-active' : '')}
                              to={to}
                              onClick={this.handleCloseDropdown}
                            >{name}</MenuItem>
                          );
                        },
                      )
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

NavDropDown.defaultProps = {
  className: '',
};

NavDropDown.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.any,
  linkName: PropTypes.string,
  linkIndex: PropTypes.number,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
      icon: PropTypes.element,
      onClick: PropTypes.func,
    }),
  ),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};
