/**
 * Created by jacob.mendt@pikobytes.de on 03.09.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import {
  MODULE_USER_SET_KEYCLOAK,
} from './actions';

const initialState = {
  keycloak: undefined,
};

const moduleUserReducer = function (state = initialState, action) {
  switch (action.type) {
    case MODULE_USER_SET_KEYCLOAK: {
      return Object.assign({}, state, {
        keycloak: action.keycloak,
      });
    }

    default: {
      return state;
    }
  }
};

export default moduleUserReducer;
