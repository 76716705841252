export const MODULE_CHART_UPDATE_CACHEKEY = 'MODULE_CHART_UPDATE_CACHEKEY';
export const MODULE_CHART_UPDATE_STORAGE = 'MODULE_CHART_UPDATE_STORAGE';
export const MODULE_CHART_UPDATE_TIMEEXTENT = 'MODULE_CHART_UPDATE_TIMEEXTENT';
export const MODULE_CHART_UPDATE_TIMEZONE = 'MODULE_CHART_UPDATE_TIMEZONE';
export const MODULE_CHART_OVERVIEW_UPDATE_STORAGE = 'MODULE_CHART_OVERVIEW_UPDATE_STORAGE';

/**
 * Update the chartsStorage
 * @param {Immutable.Map} chartStorage
 * @param {[moment, moment]} chartsTimeExtent
 * @param {[moment, moment]} overviewTimeRange
 * @returns {{
 *   type: string,
 *   chartsStorage: Immutable.Map,
 *   chartsTimeExtent: [moment, moment],
 *   overviewTimeRange: [moment, moment],
 * }}
 */
export function updateChartStorage(chartsStorage, chartsTimeExtent, overviewTimeRange) {
  return {
    type: MODULE_CHART_UPDATE_STORAGE,
    chartsStorage,
    chartsTimeExtent,
    overviewTimeRange,
  };
}

/**
 * Update overviewChartsStorage
 * @param {Immutable.Map} overviewChartsStorage
 * @returns {{
 *   type: string,
 *   chartsStorage: Immutable.Map,
 * }}
 */
export function updateOverviewChartsStorage(overviewChartsStorage) {
  return {
    type: MODULE_CHART_OVERVIEW_UPDATE_STORAGE,
    overviewChartsStorage,
  };
}

/**
 * Updates the cacheKey
 * @param {number} cacheKey
 * @return {{cacheKey: number, type: string}}
 */
export function updateCacheKey(cacheKey) {
  return {
    type: MODULE_CHART_UPDATE_CACHEKEY,
    cacheKey,
  };
}

/**
 * Dispatches an zoom event to a given time extent.
 * @param {string} eventSource
 * @param {[moment, moment]} timeExtent
 * @param {number} componentId Id of the components who dispatched the event
 * @return {{type: string, eventSource: *, timeWindow: *}}
 */
export function updateChartTimeExtent(eventSource, timeExtent, componentId = 0) {
  return {
    type: MODULE_CHART_UPDATE_TIMEEXTENT,
    eventSource,
    timeExtent,
    componentId,
  };
}

/**
 * Actions that signals an update of the timeZone used for displaying data within
 * the charts.
 * @param {string} timeZone
 * @return {{type: string, timeZone: string}}
 */
export function updateChartTimeZone(timeZone) {
  return {
    type: MODULE_CHART_UPDATE_TIMEZONE,
    timeZone,
  };
}
