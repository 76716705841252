/**
 * Created by jacob.mendt@pikobytes.de on 06.05.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
/**
 * @type {{LESS_THEN_AND_EQUAL: number, GREATER_THEN_AND_EQUAL: number}}
 */
export const ENUM_BOOL_DATE_OPERATORS = {
  DATE_GREATER_THEN_AND_EQUAL: 'DATE_GREATER_THEN_AND_EQUAL',
  DATE_LESS_THEN_AND_EQUAL: 'DATE_LESS_THEN_AND_EQUAL'
};

/**
 * @type {{SPATIAL_FILTER: string, TOPIC_FILTER: string, NETWORK_FILTER: string, DATE_FILTER: string}}
 */
export const ENUM_FILTER_TYPES = {
  DATE_FILTER: `sh-filter-date`,
  NETWORK_FILTER: `sh-filter-networks`,
  SPATIAL_FILTER: `sh-filter-spatial`,
  TOPIC_FILTER: `sh-filter-topic`,
};

/**
 * Returns a network filter
 * @param {[{ id: string, type: string }]} filterEntries
 * @returns {{ terms: * }}
 */
export function getFilterNetwork(filterEntries) {
  // 1.) Build network filters
  const newNetworks = [];
  filterEntries.forEach(
    ({id, type}) => {
      if (type === 'network') {
        newNetworks.push(id);
      }
    }
  );

  return newNetworks.length > 0 ? {
    key: 'networks',
    value: newNetworks,
  } : undefined;
}

/**
 * Returns a date filter
 * @param {[{ id: string, type: string }]} filterEntries
 * @returns {{bool: {must: Array}}|undefined}
 */
export function getFilterDate(filterEntries) {
  let minValue = undefined;
  let maxValue = undefined;
  filterEntries.forEach(
    ({id, type, value}) => {
      if (type === 'time') {
        const rawValue = value.split(' ')[1];
        if (id === ENUM_BOOL_DATE_OPERATORS.DATE_GREATER_THEN_AND_EQUAL) {
          minValue = rawValue;
        } else if (ENUM_BOOL_DATE_OPERATORS.DATE_LESS_THEN_AND_EQUAL) {
          maxValue = rawValue;
        }
      }
    }
  );

  if (minValue !== undefined || maxValue !== undefined) {
    return {
      key: 'date_range',
      value: Object.assign(
        {},
        minValue !== undefined ? { min: minValue } : {},
        maxValue !== undefined ? { max: maxValue } : {},
      )
    }
  }

  return undefined;
}

/**
 * Functions checks the input validation metadata and returns only metadata which
 * matches the criteria for usage within the moduleInterpolation module.
 *
 * @param {Immutable.Map} metadata
 * @returns {{
 *   [string]: {
 *     aggregation: string,
 *     defaultScale: number[],
 *     id: string,
 *     interpolator: {{ type: string, value: null|number }}
 *     measuredProperty: string,
 *     name: string,
 *     substance: string,
 *     uom: string
 *   }
 * }}
 */
export function filterMetadata(metadata) {
  const phenomenonLayers = {};
  metadata.get('phenomenons').forEach((obj, key) => {
    if (
      obj.lblShort !== undefined &&
      obj.measuredProperty !== undefined &&
      obj.substance !== undefined &&
      obj.defaultScale !== undefined &&
      obj.aggregation !== undefined &&
      obj.interpolator !== undefined &&
      obj.uom !== undefined
    ) {
      phenomenonLayers[key] = {
        aggregation: obj.aggregation.toUpperCase(),
        id: key,
        interpolator: obj.interpolator,
        measuredProperty: obj.measuredProperty,
        defaultScale: obj.defaultScale,
        substance: obj.substance,
        name: obj.lblShort,
        uom: obj.uom,
      };
    }
  });

  return phenomenonLayers;
}
