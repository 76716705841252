/**
 * Created by jacob.mendt@pikobytes.de on 03.09.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Keycloak from 'keycloak-js';
import {
  isAuthenticated,
  SUPPORTED_USER_ROLES,
} from '../../core/authorization';
import ViewNoRights from '../../views/ViewNoRights/ViewNoRights';

export const SUBPATH_USERINFO = '/info';
export const SUBPATH_LOGOUT = '/logout';

function Logout({ keycloak }) {
  keycloak.logout({
    redirectUri: `${window.location.origin}`,
  });

  return (
    <p>Logout user. Should be starting automatically.</p>
  );
}

export class UserPage extends Component {
   render() {
    const {
      keycloak,
      match,
    } = this.props;

    if (!isAuthenticated(keycloak)) {
      return (<ViewNoRights />);
    }

    // Extract relevant user information
    const tokenInformation = keycloak.tokenParsed;
    const email = tokenInformation.email;
    const name = tokenInformation.name;
    const username = tokenInformation.preferred_username;
    const roles = tokenInformation.realm_access.roles.filter(r => SUPPORTED_USER_ROLES.includes(r));

    return (
      <div className="sh-container user-info">
        <div>
        <Switch>
          <Route path={`${match.path}${SUBPATH_USERINFO}`}>
            <React.Fragment>
              <h3>UserInfo</h3>
              <br/>
              <p><strong>User: {username}</strong></p>
              <p>Name: {name}</p>
              <p>Email: {email}</p>
              <br/>
              <p><strong>Roles:</strong></p>
              <p>{roles.join(', ')}</p>
            </React.Fragment>
          </Route>
          <Route path={`${match.path}${SUBPATH_LOGOUT}`}>
            <Logout keycloak={keycloak} />
          </Route>
        </Switch>
        </div>
      </div>
    )
  }
}

UserPage.propTypes =  {
  keycloak: PropTypes.instanceOf(Keycloak),
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

// connect the components with the global application state.
export default withRouter(
  connect(
    store => ({
      keycloak: store.main.keycloak,
    }),
    undefined,
  )(UserPage),
);
