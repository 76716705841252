export const LAYOUT_DEFAULT_SET_WIDTH = 'LAYOUT_DEFAULT_SET_WIDTH';
export const LAYOUT_DFEAULT_SHOW_FEEDBACK = 'LAYOUT_DFEAULT_SHOW_FEEDBACK';
export const LAYOUT_DEFAULT_SHOW_MAIN = 'LAYOUT_DEFAULT_SHOW_MAIN';
export const LAYOUT_DEFAULT_SHOW_SIDE = 'LAYOUT_DEFAULT_SHOW_SIDE';

/**
 * Set the width of the main-view and sidebar-view in pixel.
 * @param {number} width
 * @returns {{type: string, width: *}}
 */
export function setWidth(widthMain, widthSidebar) {
  return {
    type: LAYOUT_DEFAULT_SET_WIDTH,
    widthMain,
    widthSidebar,
  };
}

/**
 * Action for changing the view of the MainLayout to 'main'.
 * @param {{ msg: string, level: string }|undefined} feedback
 * @returns {{type: string, feedback: { msg: string, level: string }|undefined }}
 */
export function showFeedback(feedback) {
  return {
    type: LAYOUT_DFEAULT_SHOW_FEEDBACK,
    feedback: feedback,
  };
}

/**
 * Action for changing the view of the MainLayout to 'main'.
 * @returns {{type: string }}
 */
export function showMainView() {
  return {
    type: LAYOUT_DEFAULT_SHOW_MAIN,
  };
}

/**
 * Action for changing the view of the MainLayout to 'side'.
 * @returns {{type: string }}
 */
export function showSideView() {
  return {
    type: LAYOUT_DEFAULT_SHOW_SIDE,
  };
}
