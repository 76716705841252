/* eslint-disable eqeqeq */
import moment from 'moment/moment';
import isUndefined from 'lodash.isundefined';

/**
 * Signals if a given resolution should be interpreted as aggregation resolution
 * or raw resolution.
 * @type {{AGGREGATION: number, RAW: number}}
 */
export const RESOLUTION_TYPES = {
  AGGREGATION: 0,
  RAW: 1,
};

/**
 * @param {ZoomLevelType} level
 * @returns {{ size: number, sizeUnit: string, type: number, interval: Interval, zoom: number }|undefined}
 */
export function cloneZoomLevel(level) {
  if (isUndefined(level)) {
    console.warn('Can not clone a zoomLevel of type undefined');
  }

  return {
    // year in seconds * 500 ~ 500 years
    size: level.size,
    sizeUnit: level.sizeUnit,
    zoom: level.zoom,
    interval: level.interval.clone(),
    type: level.type,
  };
}

/**
 * Calculates the number of occurrens from an interval within an timeExtent.
 * @param {[moment, moment]} timeExtent
 * @param {Interval} interval
 * @returns {number}
 */
export function countIntervalsWithinTimeExtent(timeExtent, interval) {
  return divideExtentSizeByIntervalSize(timeExtent[1].diff(timeExtent[0], 'seconds'), interval);
}

/**
 * Divides the supplied extent Size by the interval size in seconds
 * @param extentSize
 * @param interval
 * @returns {number}
 */
export function divideExtentSizeByIntervalSize(extentSize, interval) {
  return Math.floor(
    Math.abs(extentSize) / interval.getTotalSeconds(),
  );
}

/**
 * Function returns the number of maximum features to fetch.
 * @returns {number}
 */
export function maxFeatures() {
  return 1000;
}

/**
 * This utility module contains functions for working with arrays of resolutions.
 */
const zoom = {
  /**
   * This function approximates the correct zoom level for dispatching a fakeRedraw
   * event to the layer. It expected that the base unit of the zoomLevels are seconds.
   * @param {[ZoomLevelType]} zoomLevels
   * @param {[moment.utc]} extent
   * @param {number|undefined}
   * @returns {ZoomLevelType}
   */
  approximateBasedOnSeconds(zoomLevels, extent, optMaxFeatures) {
    const extentInSeconds = extent[1].diff(extent[0], 'seconds');
    const maxFeatures = optMaxFeatures !== undefined
      ? optMaxFeatures
      : 500;

    let zoomLevel = zoomLevels[0];
    zoom.cloneZoomLevels(zoomLevels).forEach((z) => {
      // expected feature count which would be fetched for this ZoomLevelType and
      // time extent
      const featureFetchCount = extentInSeconds / z.interval.getTotalSeconds();
      if (Math.abs(z.size - extentInSeconds) < Math.abs(zoomLevel.size - extentInSeconds) &&
          featureFetchCount < maxFeatures) {
        zoomLevel = z;
      }
    });
    return zoomLevel;
  },

  /**
   * Function calculates the given scaleFactor and returns it.
   *
   * @param {[ZoomLevelType]} levels
   * @param {ZoomLevelType} zoomLevel
   * @returns {number}
   */
  calculateScaleFactor(levels, zoomLevel) {
    return levels[0].size / zoomLevel.size;
  },

  /**
   * Calculates for a given time period a approximate scale factor.
   *
   * @param {[ZoomLevelType]} levels
   * @param {[moment.utc]} periode
   * @returns {number} scaleFactor
   */
  calculateScaleFactorForTimePeriod(levels, periode) {
    const minZoom = zoom.getMinZoom(levels);
    const intervalInSizeUnit = periode[1].diff(periode[0], minZoom.sizeUnit);


    if (minZoom.size < intervalInSizeUnit) {
      throw new Error('Interval size exceeds support zoom sizes.');
    }

    return minZoom.size / intervalInSizeUnit;
  },

  /**
   * @param {[ZoomLevelType]} levels
   * @returns {[ZoomLevelType]}
   */
  cloneZoomLevels(levels) {
    const clone = [];
    levels.forEach((level) => {
      clone.push(cloneZoomLevel(level));
    });
    return clone;
  },

  /**
   * @param {[ZoomLevelType]} levels
   * @returns {ZoomLevelType}
   */
  getMaxZoom(levels) {
    return levels[
      zoom.getZoomIndexForScaleFactor(
        levels,
        zoom.calculateScaleFactor(levels, levels[levels.length - 1]),
      )];
  },

  /**
   * @param {[ZoomLevelType]} levels
   * @returns {ZoomLevelType}
   */
  getMinZoom(levels) {
    return levels[
      zoom.getZoomIndexForScaleFactor(
        levels,
        zoom.calculateScaleFactor(levels, levels[0]),
      )];
  },

  /**
   * Returns a ZoomLevelType for a given scaleFactor.
   *
   * @param {[ZoomLevelType]} levels
   * @param {number} scaleFactor
   * @returns {ZoomLevelType|undefined}
   */
  getZoomForScaleFactor(levels, scaleFactor) {
    const index = zoom.getZoomIndexForScaleFactor(levels, scaleFactor);
    return !isUndefined(index)
      ? levels[index]
      : undefined;
  },


  /**
   * Returns the index of a ZoomLevelType, which scaleFactor matches the given scale.
   *
   * @param {[ZoomLevelType]} levels
   * @param {number} scaleFactor
   * @returns {ZoomLevelType}
   */
  getZoomIndexForScaleFactor(levels, scaleFactor) {
    let index;

    levels.forEach((zoomLevel, i) => {
      if (Math.floor(scaleFactor) === Math.floor(
        zoom.calculateScaleFactor(levels, zoomLevel),
      )) {
        index = i;
      }
    });

    return index;
  },

  /**
   * Returns the next zoomLevel for a given scaleFactor.
   *
   * @param {[ZoomLevelType]} levels
   * @param {number} scaleFactor
   * @param {number|undefined} optTolerance
   * @returns {ZoomLevelType}
   */
  getNextZoomLevelForScaleFactor(levels, scaleFactor, optTolerance) {
    let index = levels.length - 1;
    let delta = zoom.calculateScaleFactor(levels, zoom.getMaxZoom(levels));
    const tolerance = !isUndefined(optTolerance)
      ? 1 + optTolerance
      : 1;

    levels.forEach((zoomLevel, i) => {
      const currentScaleFactor = zoom.calculateScaleFactor(levels, zoomLevel);
      const currentDelta = Math.round(currentScaleFactor) - Math.round(scaleFactor +
          (tolerance * currentScaleFactor) - currentScaleFactor);

      if (currentDelta > 0 && currentDelta < delta) {
        index = i;
        delta = currentDelta;
      }
    });

    return levels[index];
  },

  /**
   * Returns the previous zoomLevel for a given scaleFactor.
   *
   * @param {[ZoomLevelType]} levels
   * @param {number} scaleFactor
   * @param {number|undefined} optTolerance in percentage [0...1.0]
   * @returns {ZoomLevelType}
   */
  getPreviousZoomLevelForScaleFactor(levels, scaleFactor, optTolerance) {
    let index = 0;
    let delta = zoom.calculateScaleFactor(levels, zoom.getMaxZoom(levels));
    const tolerance = !isUndefined(optTolerance)
      ? 1 + optTolerance
      : 1;

    levels.forEach((zoomLevel, i) => {
      const currentScaleFactor = zoom.calculateScaleFactor(levels, zoomLevel);
      const currentDelta = Math.round(currentScaleFactor) - scaleFactor +
        (tolerance * currentScaleFactor) - currentScaleFactor;

      if (currentDelta < 0 && (Math.abs(currentDelta) < Math.abs(delta) || currentScaleFactor === delta)) {
        index = i;
        delta = currentDelta;
      }
    });

    return levels[index];
  },

  /**
   * Detects the zoomLevel which best matches a given timeExtent for a specific
   * setting of canvasWidth, desiredIntervalWidth and minimalAllowedInternvalWidth.
   *
   * @param {[moment, moment]} timeExtent
   * @param {[ZoomLevelType]} zoomLevels
   * @param {number=} canvasWidth
   * @param {number=} desiredIntervalWidth
   * @param {number=} minimalAllowedIntervalWidth
   * @returns {{ size: number, sizeUnit: string, type: number, interval: Interval, zoom: number }}
   */
  getZoomLevelForTimeExtent(
    timeExtent,
    zoomLevels,
    canvasWidth = 1000,
    desiredIntervalWidth = 10,
    minimalAllowedIntervalWidth = 1,
  ) {
    if (minimalAllowedIntervalWidth > desiredIntervalWidth) {
      throw new Error('It is not allowed that the minimalAllwoedIntervalWidth is larger the desriredIntervalWidth');
    }

    const diPxWidth = desiredIntervalWidth / window.devicePixelRatio;
    const maiPxWidth = minimalAllowedIntervalWidth / window.devicePixelRatio;
    const timePeriodeInSeconds = Math.abs(timeExtent[1].diff(timeExtent[0], 'seconds'));
    const secondsPerPx = timePeriodeInSeconds / canvasWidth;
    let zoomLevel;
    let currentDiff;

    for (let i = 0; i < zoomLevels.length; i++) {
      const intervalWidth = zoomLevels[i].interval.getTotalSeconds() / secondsPerPx;
      const diffFromDi = Math.abs(diPxWidth - intervalWidth);

      // currently there is no zoomLevel set, so we always set a zoomLevel.
      if (zoomLevel === undefined) {
        currentDiff = diffFromDi;
        zoomLevel = cloneZoomLevel(zoomLevels[i]);
      }

      // This helps us to decide which zoomLevel to choose. We try to select a zoomLevel
      // which minimized the delta of the interval width to the desiredIntervalWidth
      // and does not fall under the restriction of the minimalIntervalWidth
      if (
        // In normal case we do not want that zoomLevel with an interval size smaller
        // than the maiPxWidth will be choosen
        intervalWidth > maiPxWidth &&
        diffFromDi < currentDiff
      ) {
        currentDiff = diffFromDi;
        zoomLevel = cloneZoomLevel(zoomLevels[i]);
      }
    }

    return zoomLevel !== undefined
      ? zoomLevel
      : cloneZoomLevel(zoomLevels[0]);
  },

  /**
   * Checks if a given interval is within the given set of zoom levels. In case
   * the size of the zoomLevel is described in a unit smaller than seconds this
   * functions fails currently.
   *
   * @param {[ZoomLevelType]} levels
   * @param {ZoomLevelType} zoomLevel
   * @returns {boolean}
   */
  isWithinMinMaxZoom(levels, zoomLevel) {
    const minZoomLevel = levels[0].interval.getTotalSeconds();
    const maxZoomLevel = levels[levels.length - 1].interval.getTotalSeconds();
    const zoomLevelSizeInSeconds = zoomLevel.interval.getTotalSeconds();

    return zoomLevelSizeInSeconds <= minZoomLevel && zoomLevelSizeInSeconds >= maxZoomLevel;
  },

  /**
   * @param {[ZoomLevelType]} levels
   * @param {number=} optMaxSize
   * @param {number=} optMinSize
   * @returns {[ZoomLevelType]}
   */
  trim(levels, optMaxSize, optMinSize) {
    const arr = [];
    const max = !isUndefined(optMaxSize)
      ? optMaxSize
      : levels[0].interval.getTotalSeconds();
    const min = !isUndefined(optMinSize)
      ? optMinSize
      : levels[levels.length - 1].interval.getTotalSeconds();

    levels.forEach((zoomLevel) => {
      const intervalSize = zoomLevel.interval.getTotalSeconds();
      if (intervalSize <= max && intervalSize >= min) {
        arr.push(cloneZoomLevel(zoomLevel));
      }
    });

    return arr;
  },

  /**
   * @param {[ZoomLevelType]} conf
   * @param {[moment.utc]|undefined} optExtent
   * @param {{ size: number }} conf
   * @returns {[*,*]}
   */
  getExtentForZoomConfiguration(conf, optExtent) {
    const referenceDateTime = '2050-01-01T00:00:00.000Z';

    return !isUndefined(optExtent)
      ? [
        optExtent.maxDate.clone().add({ seconds: -1 * conf[0].size }),
        optExtent.maxDate.clone(),
      ] : [
        moment.utc(referenceDateTime).add({ seconds: -1 * conf[0].size }),
        moment.utc(referenceDateTime),
      ];
  },
};

export default zoom;
