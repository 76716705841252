export const BATCHDOWNLOAD_UPDATE_CHART_SETTINGS = 'BATCHDOWNLOAD_UPDATE_CHART_SETTINGS';
export const BATCHDOWNLOAD_DELETE_CHART_SETTINGS = 'BATCHDOWNLOAD_DELETE_CHART_SETTINGS';

export function updateChartSettings(chartSettings, chartId) {
  return {
    type: BATCHDOWNLOAD_UPDATE_CHART_SETTINGS,
    chartSettings,
    chartId,
  };
}

export function deleteChartSettings(chartId) {
  return {
    type: BATCHDOWNLOAD_DELETE_CHART_SETTINGS,
    chartId,
  };
}
