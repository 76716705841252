/**
 * Created by jacob.mendt@pikobytes.de on 05.05.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import Immutable, { List } from 'immutable';
import uniqueId from 'lodash.uniqueid';
import { SCREEN_SIZE_MD } from '../options';
import { AGGREGATION_TYPES } from '../chart/structs/aggregation';
import { SUPPORTED_TIMEZONES } from '../chart/structs/timezone';
import { INTERPOLATOR_TYPE } from '../chart/structs/interpolator';
import { DEFAULT_DATETIME, SUPPORTED_TIME_RANGE } from './options';
import {
  MODULE_OSW_UPDATE_METADATA,
  MODULE_OSW_UPDATE_FILTERS,
  MODULE_OSW_UPDATE_FILTERS_EXPLICIT,
  MODULE_SH_SET_INTERPOLATION_SETTINGS,
  MODULE_SH_SET_FILTERENTRIES,
  MODULE_SH_SET_SEARCH_METADATA,
  MODULE_SH_SET_SEARCH_LOADING,
  MODULE_SH_UPDATE_INTERPOLATIONDATA,
  MODULE_SH_UPDATE_SEARCHDATA,
} from './actions';
import {
  COLOR_SCALE,
  createStops,
} from './layerstyles';
import { filterMetadata } from './filter';

const initialState = {
  metadata: Immutable.Map(),
  filterViewOpen: window.innerWidth > SCREEN_SIZE_MD,
  filterEntries: Immutable.Map(),
  interpolationDataset: Immutable.Map({
    keys: [],
    data: {},
    phen: undefined,
  }),
  interpolationPhenomenons: [],
  interpolationSettings: Immutable.Map({
    labelOn: true,
    legendStyle: Immutable.List(createStops([0, 100], COLOR_SCALE.GREEN_5)),
    selectedAggregation: AGGREGATION_TYPES.MEAN,
    selectedDateTime: DEFAULT_DATETIME.toISOString(),
    selectedInterpolator: {
      type: INTERPOLATOR_TYPE.NEAREST,
      value: undefined,
    },
    selectedPhenomenon: undefined,
    selectedScale: [0, 100],
    selectedTimeResolution: 'P1D',
    selectedTimeZone: SUPPORTED_TIMEZONES.UTC,
    timeRange: [SUPPORTED_TIME_RANGE[0].toISOString(), SUPPORTED_TIME_RANGE[1].toISOString()],
  }),
  searchFilters: Immutable.Map({}),
  searchDataset: Immutable.Map({
    docType: undefined,
    data: List([]),
    topicSummary: undefined,
  }),
  searchDatasetLoading: false,
};

const searchReducer = function (state = initialState, action) {
  switch (action.type) {
    case MODULE_SH_SET_INTERPOLATION_SETTINGS: {
      return Object.assign({}, state, {
        interpolationSettings: action.interpolationSettings,
      });
    }

    case MODULE_SH_SET_SEARCH_LOADING: {
      return Object.assign({}, state, {
        searchDatasetLoading: action.isLoading,
      });
    }

    case MODULE_SH_UPDATE_INTERPOLATIONDATA: {
      return Object.assign({}, state, {
        interpolationDataset: action.dataset,
      });
    }

    case MODULE_SH_SET_FILTERENTRIES: {
      return Object.assign({}, state, {
        filterEntries: action.filterEntries,
      });
    }

    case MODULE_SH_UPDATE_SEARCHDATA: {
      return Object.assign({}, state, {
        searchDataset: Immutable.Map({
          docType: action.dataset.docType,
          data: Immutable.List(action.dataset.data),
          topicSummary: action.dataset.topicSummary,
        }),
      });
    }

    case MODULE_SH_SET_SEARCH_METADATA: {
      const newMetadata = Immutable.Map(action.metadata);

      const newNetworks = newMetadata.get('defaultNetworks');
      const networkFilters = {};
      newNetworks.forEach((network) => {
        const networkMetadata = newMetadata.get('networks').find(n => n.id === network);
        if (networkMetadata !== undefined) {
          const { id, lbl } = networkMetadata;
          networkFilters[network] = { id, value: lbl, type: 'network' };
        }
      });

      return Object.assign({}, state, {
        filterEntries: Immutable.Map(networkFilters),
        interpolationPhenomenons: Object.values(filterMetadata(newMetadata)),
        metadata: newMetadata,
      });
    }

    case MODULE_OSW_UPDATE_FILTERS: {
      let { searchFilters } = state;
      const { filters } = action;
      filters.forEach(({ filterKey, filterQuery }) => {
        if (filterQuery === undefined) {
          searchFilters = searchFilters.delete(filterKey);
        } else {
          searchFilters = searchFilters.set(filterKey, filterQuery);
        }
      });
      return Object.assign({}, state, {
        searchFilters: searchFilters,
      });
    }

    case MODULE_OSW_UPDATE_FILTERS_EXPLICIT: {
      let { searchFilters } = state;
      const { filters } = action;
      filters.forEach(({ filterKey, filterQuery }) => {
        if (filterQuery === undefined) {
          searchFilters = searchFilters.delete(filterKey);
        } else {
          searchFilters = searchFilters.set(filterKey, filterQuery);
        }
      });
      return Object.assign({}, state, {
        searchFilters: searchFilters,
      });
    }

    case MODULE_OSW_UPDATE_METADATA: {
      const { metadata } = action;
      return Object.assign({}, state, {
        interpolationPhenomenons: Object.values(filterMetadata(action.metadata)),
        metadata: metadata.set('revision', uniqueId()),
      });
    }

    default: {
      return state;
    }
  }
};

export default searchReducer;
