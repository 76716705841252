import {
  MODULE_MAP_DELETE,
  MODULE_MAP_FINISH_MAPLOADING,
  MODULE_MAP_UPDATE_FEATURECOUNT,
  MODULE_MAP_UPDATE_MAPVIEW,
} from './actions';

const initialState = {
  featureCount: 0,
  map: undefined,
  view: undefined,
};

const mapViewReducer = function (state = initialState, action) {
  switch (action.type) {
    case MODULE_MAP_DELETE: {
      return Object.assign({}, state, {
        map: undefined,
      });
    }

    case MODULE_MAP_FINISH_MAPLOADING: {
      return Object.assign({}, state, {
        map: action.map,
      });
    }

    case MODULE_MAP_UPDATE_FEATURECOUNT: {
      return Object.assign({}, state, {
        featureCount: action.count,
      });
    }

    case MODULE_MAP_UPDATE_MAPVIEW: {
      return Object.assign({}, state, {
        view: action.view,
      });
    }

    default: {
      return state;
    }
  }
};

export default mapViewReducer;
