/**
 * Created by jacob.mendt@pikobytes.de on 30.06.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import round from 'lodash.round';

/**
 * @type {{ORANGE_6: string[], GREEN_5: string[]}}
 */
export const COLOR_SCALE = {
  GREEN_5: ['#C7E9B4','#7FCDBB','#41B6C4','#2C7FB8','#253494'],
  ORANGE_6: ['#FFC300', '#F1920E', '#E3611C', '#C70039', '#900C3F', '#5A1846'],
};

/**
 *
 * @param {number[]} scale
 * @param {string[]} color
 * @returns {[number, string][]}
 */
export function createStops(scale, color) {
  const stops = color.length;
  const delta = Math.abs(scale[1] - scale[0]);
  const roundingFactor = delta < 0.1
    ? 3
    : delta < 1
      ? 2
      : 1;
  const stepSize = round(delta / stops, roundingFactor);
  let currentStep = round(scale[0] + (stepSize / 2), roundingFactor);
  return color.map(
    (colorValue, index) => {
      if (index !== 0) {
        currentStep = round(currentStep + stepSize, roundingFactor);
      }
      return [currentStep, colorValue];
    }
  );
}
