/**
 * Created by jacob.mendt@pikobytes.de on 05.05.20.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
// actions regarding the fetching of metadata information of the sensors
export const MODULE_OSW_UPDATE_METADATA = 'MODULE_OSW_UPDATE_METADATA';
export const MODULE_OSW_UPDATE_FILTERS = 'MODULE_OSW_UPDATE_FILTERS';
export const MODULE_OSW_UPDATE_FILTERS_EXPLICIT = 'MODULE_OSW_UPDATE_FILTERS_EXPLICIT';
export const MODULE_SH_SET_INTERPOLATION_SETTINGS = 'MODULE_SH_SET_INTERPOLATION_SETTINGS';
export const MODULE_SH_SET_FILTERENTRIES = 'MODULE_SH_SET_FILTERENTRIES';
export const MODULE_SH_SET_SEARCH_LOADING = 'MODULE_SH_SET_SEARCH_LOADING';
export const MODULE_SH_SET_SEARCH_METADATA = 'MODULE_SH_SET_SEARCH_METADATA';
export const MODULE_SH_UPDATE_INTERPOLATIONDATA = 'MODULE_SH_UPDATE_INTERPOLATIONDATA';
export const MODULE_SH_UPDATE_SEARCHDATA = 'MODULE_SH_UPDATE_SEARCHDATA';

/**
 * @param {Immutable.Map} newInterpolationSettings
 * @returns {{interpolationSettings: Immutable.Map, type: string}}
 */
export function setInterpolationSettings(newInterpolationSettings) {
  return {
    type: MODULE_SH_SET_INTERPOLATION_SETTINGS,
    interpolationSettings: newInterpolationSettings,
  }
}

/**
 * @param {Immutable.Map} newFilterEntries
 * @returns {{filterEntries: Immutable.Map, type: string}}
 */
export function setFilterEntries(newFilterEntries) {
  return {
    type: MODULE_SH_SET_FILTERENTRIES,
    filterEntries: newFilterEntries,
  }
}


/**
 * @param {boolean} newIsLoading
 * @returns {{isLoading: boolean, type: string}}
 */
export function setSearchLoading(newIsLoading) {
  return {
    type: MODULE_SH_SET_SEARCH_LOADING,
    isLoading: newIsLoading,
  }
}

/**
 * Updates the search metadata.
 * @param {{
 *    networks: *,
 *    phenomenons: *,
 *    selection: *,
 *    topics: *,
 * }} metadata
 * @returns {{type: string, metadata: * }}
 */
export function setSearchMetadata(metadata) {
  return {
    type: MODULE_SH_SET_SEARCH_METADATA,
    metadata,
  };
}



/**
 * Update the metadata.
 * @param {Map} metadata
 * @returns {{type: string, metadata: Map}}
 */
export function updateMetadata(metadata) {
  return {
    type: MODULE_OSW_UPDATE_METADATA,
    metadata,
  };
}

/**
 * Update the search filters.
 * @param {[{filterKey: string, filterQuery: *}]}filters
 * @returns {{type: string, filters: *}}
 */
export function updateFilters(filters) {
  return {
    type: MODULE_OSW_UPDATE_FILTERS,
    filters,
  };
}

/**
 * Update the search filters. This signals that it is an explicit update, meaning
 * that the update is coming from an specific user interaction, but shoud exclude
 * map interactions.
 * @param {[{filterKey: string, filterQuery: *}]}filters
 * @returns {{type: string, filters: *}}
 */
export function updateFiltersExplicit(filters) {
  return {
    type: MODULE_OSW_UPDATE_FILTERS_EXPLICIT,
    filters,
  };
}

/**
 * @param {Immutable.Map} newDataset
 * @returns {{dataset: Immutable.Map, type: string}}
 */
export function updateInterpolationData(newDataset) {
  return {
    type: MODULE_SH_UPDATE_INTERPOLATIONDATA,
    dataset: newDataset,
  }
}

/**
 * @param {{
 *   data: *[],
 *   docType: string,
 *   topicSummary: { key: string, count: number }[]|undefined,
 * }} newData
 * @returns {{
 *   dataset: {
 *     data: *[],
 *     docType: string,
 *     topicSummary: { key: string, count: number }[]|undefined,
 *   },
 *   type: string
 * }}
 */
export function updateSearchData(newData) {
  return {
    type: MODULE_SH_UPDATE_SEARCHDATA,
    dataset: newData,
  }
}
