export const MODULE_MAP_DELETE = 'MODULE_MAP_DELETE';
export const MODULE_MAP_FINISH_MAPLOADING = 'MODULE_MAP_FINISH_MAPLOADING';
export const MODULE_MAP_UPDATE_FEATURECOUNT = 'MODULE_MAP_UPDATE_FEATURECOUNT';
export const MODULE_MAP_UPDATE_MAPVIEW = 'MODULE_MAP_UPDATE_MAPVIEW';

/**
 * Action signals that a map object was loaded and allows access to it over the
 * global state.
 *
 * @param {{ map: L.map }} map
 * @returns {{type: string, map: L.map }}
 * }}
 */
export function finishMapLoading(map) {
  return {
    type: MODULE_MAP_FINISH_MAPLOADING,
    map,
  };
}

/**
 * Action signals that a map object was deleted.
 *
 * @returns {{type: string }}
 * }}
 */
export function finishMapDelete() {
  return {
    type: MODULE_MAP_DELETE,
  };
}

/**
 * Update the count of which features which are currently displayed.
 * @param count
 * @returns {{type: string, count: *}}
 */
export function updateFeatureCount(count) {
  return {
    type: MODULE_MAP_UPDATE_FEATURECOUNT,
    count,
  };
}

/**
 * Action for changing the map layouts following the redux/flux pattern. A map layouts
 * always consist out of center [lat, lng] and a zoom level.
 *
 * @param {Immutable.Map} view
 * @returns {{ type: string, view: Immutable.Map }}
 */
export function updateMapView(view) {
  return {
    type: MODULE_MAP_UPDATE_MAPVIEW,
    view,
  };
}
