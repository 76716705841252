import moment from 'moment/moment';
import bind from 'lodash.bind';
import has from 'lodash.has';
import isNumber from 'lodash.isnumber';

/**
 * @type {{YEARLY: number, MONTHLY: number, DAILY: number, HOURLY: number, MINUTES: number, SECONDS: number, EXACT: number}}
 */
export const INTERVAL_TYPES = {
  YEARLY: 0,
  MONTHLY: 1,
  DAILY: 2,
  HOURLY: 3,
  MINUTES: 4,
  SECONDS: 5,
  EXACT: 6,
};

/**
 * Functions checks if a given time interval is expressed in the units month or year. Expect that a year consists
 * of 365 and a months on 28 days.
 *
 * @static
 * @param {Interval} interval
 * @return {boolean}
 */
export function isIntervalMonthOrYear(interval) {
  return interval.years > 0 || interval.months > 0;
}

/**
 * Format a given timeExtent and interval to a human readable string. The following function
 * decides which kind of format behavior we should it choose. The goal is to
 * produce a human readable string. Therefor the interval guessing is based
 * on the intervals defined within moduleOsw/chart/zoomlevel
 *
 * @param {[moment, moment]}
 * @param {Interval} interval
 * @param {number} timeZoneOffset
 * @returns {string}
 */
export function formatTimeExtentInterval(timeExtent, interval, timeZoneOffset = 0) {
  const sdt = timeExtent[0].add(timeZoneOffset, 'minutes');
  const edt = timeExtent[1].add(timeZoneOffset, 'minutes');
  const intervalType = interval.getIntervalType();

  // format yearly intervals and always break them down to the years of the timeExtent
  if (intervalType === INTERVAL_TYPES.YEARLY) {
    // adjust the edt so we get better matching extents in case of a one year period.
    const adjustEdt = edt.clone().subtract(1, 'seconds');
    return sdt.year() === adjustEdt.year()
      ? `${sdt.format('YYYY')}`
      : `${sdt.format('YYYY')} to ${adjustEdt.format('YYYY')}`;
  }

  // format monthly intervals
  if (intervalType === INTERVAL_TYPES.MONTHLY) {
    // adjust the edt so we get better matching extents in case of a one month period.
    const adjustEdt = edt.clone().subtract(1, 'seconds');
    return sdt.year() === adjustEdt.year() && sdt.month() === adjustEdt.month()
      ? `${sdt.format('MMM YYYY')}`
      : `${sdt.format('MMM YYYY')} to ${adjustEdt.format('MMM YYYY')}`;
  }

  // format daily intervals
  if (intervalType === INTERVAL_TYPES.DAILY) {
    // adjust the edt so we get better matching extents in case of a one month period.
    const adjustEdt = edt.clone().subtract(1, 'seconds');
    return moment.duration(edt.diff(sdt)).toISOString() === 'PT24H'
      ? `${sdt.format('DD MMM YYYY')}`
      : `${sdt.format('DD MMM YYYY')} to ${adjustEdt.format('DD MMM YYYY')}`;
  }

  // format hours and minutes intervals
  if (intervalType === INTERVAL_TYPES.HOURLY || intervalType === INTERVAL_TYPES.MINUTES) {
    return `${sdt.format('DD MMM - HH:mm')} to ${edt.format('DD MMM - HH:mm')}`;
  }

  // format seconds intervals
  if (intervalType === INTERVAL_TYPES.SECONDS) {
    return `${sdt.format('DD MMM - HH:mm:ss')} to ${edt.format('DD MMM - HH:mm:ss')}`;
  }

  console.warn('It is not recommended to use formatTimeExtentInterval without proper configuration of intervalType');
  return `${sdt.format('DD MMM YYYY HH:mm:ss')} to ${edt.format('DD MMM YYYY HH:mm:ss')}`;
}

/**
 * Interval class used by the charting timeseries grid for better calculation and
 * describing time intervals.
 */
export class Interval {
  /**
   * @param {Object|number} objectOrMillisec
   * @param {number} intervalType
   */
  constructor(objectOrMillisec, intervalType = INTERVAL_TYPES.EXACT) {
    this.millis_ = undefined;
    this.seconds = 0;
    this.minutes = 0;
    this.hours = 0;
    this.days = 0;
    this.weeks = 0;
    this.months = 0;
    this.years = 0;

    /**
     * The interval type can be used for correct printing of the interval.
     * @type {number}
     */
    this.intervalType = intervalType;

    if (isNumber(objectOrMillisec)) {
      this.millis_ = objectOrMillisec;
    } else {
      Object.keys(objectOrMillisec).forEach(
        bind((key) => {
          if (has(this, key)) {
            this[key] = objectOrMillisec[key];
          }
        }, this),
      );
    }
  }

  /**
   * @returns {Interval}
   */
  clone() {
    if (this.millis_ !== undefined) {
      return new Interval(this.millis_, this.intervalType);
    }

    return new Interval({
      years: this.years,
      months: this.months,
      weeks: this.weeks,
      days: this.days,
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
    }, this.intervalType);
  }

  /**
   * Checks if the given obj is the same as the instance.
   * @param {Interval} obj
   * @returns {boolean}
   */
  equals(obj) {
    if (!(obj instanceof Interval)) {
      return false;
    }

    if (this.millis !== obj.millis || this.seconds !== obj.seconds ||
      this.minutes !== obj.minutes ||
      this.hours !== obj.hours || this.days !== obj.days ||
      this.weeks !== obj.weeks ||
      this.months !== obj.months || this.years !== obj.years) {
      return false;
    }

    return true;
  }

  /**
   * Reduce the years to 365 days and the months to 28 days and returns a new interval object.
   * @returns {Interval}
   */
  flattenToDays() {
    if (this.years === 0 && this.months === 0 && this.weeks === 0) {
      return this;
    }

    return new Interval({
      years: 0,
      months: 0,
      weeks: 0,
      days: this.days + (this.years * 365) + Math.floor(this.months * 30.4) + (this.weeks * 7),
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
      millis_: this.millis_,
    }, this.intervalType);
  }

  /**
   * @returns {number}
   */
  getIntervalType() {
    return this.intervalType;
  }

  /**
   * Returns the interval as sum of millis based on a flattened interval.
   * @return {number}
   */
  getMillis() {
    if (this.millis_ !== undefined) {
      return this.millis_;
    }

    const interval = this.flattenToDays();
    const daysToHours = interval.days * 24;
    const hoursToMinutes = (interval.hours + daysToHours) * 60;
    const minutesToSeconds = (interval.minutes + hoursToMinutes) * 60;
    const secondsToMillis = (interval.seconds + minutesToSeconds) * 1000;
    return secondsToMillis;
  }

  /**
   * @return {number}
   */
  getTotalSeconds() {
    if (this.totalSeconds_ === undefined) {
      this.totalSeconds_ = this.getMillis() / 1000;
    }

    return this.totalSeconds_;
  }

  /**
   * Wrapper for date.isIntervalMonthOrYear
   * @returns {boolean}
   */
  hasMonthOrYear() {
    return isIntervalMonthOrYear(this);
  }

  /**
   * Calculates n * (this interval) by memberwise multiplication.
   * @param {number} n An integer.
   * @return {!Interval} n * this.
   */
  times(n) {
    return this.millis_ !== undefined
      ? new Interval(this.millis_ * n, this.intervalType)
      : new Interval(
        {
          years: this.years * n,
          months: this.months * n,
          weeks: this.weeks * n,
          days: this.days * n,
          hours: this.hours * n,
          minutes: this.minutes * n,
          seconds: this.seconds * n,
        },
        this.intervalType,
      );
  }

  /**
   * Serializes goog.date.Interval into XML Schema duration (ISO 8601 extended).
   * @see http://www.w3.org/TR/xmlschema-2/#duration
   *
   * @param {boolean=} optVerbose Include zero fields in the duration string.
   * @return {?string} An XML schema duration in ISO 8601 extended format,
   *     or null if the interval contains both positive and negative fields.
   */
  toISOString(optVerbose) {
    const minField = Math.min(
      this.years, this.months, this.days, this.hours, this.minutes,
      this.seconds);
    const maxField = Math.max(
      this.years, this.months, this.days, this.hours, this.minutes,
      this.seconds);
    if (minField < 0 && maxField > 0) {
      return null;
    }

    // Return 0 seconds if all fields are zero.
    if (!optVerbose && minField === 0 && maxField === 0) {
      return 'PT0S';
    }

    const res = [];

    // Add sign and 'P' prefix.
    if (minField < 0) {
      res.push('-');
    }
    res.push('P');

    // Add date.
    if (this.years || optVerbose) {
      res.push(`${Math.abs(this.years)}Y`);
    }
    if (this.months || optVerbose) {
      res.push(`${Math.abs(this.months)}M`);
    }
    if (this.days || optVerbose) {
      res.push(`${Math.abs(this.days)}D`);
    }

    // Add time.
    if (this.hours || this.minutes || this.seconds || optVerbose) {
      res.push('T');
      if (this.hours || optVerbose) {
        res.push(`${Math.abs(this.hours)}H`);
      }
      if (this.minutes || optVerbose) {
        res.push(`${Math.abs(this.minutes)}M`);
      }
      if (this.seconds || optVerbose) {
        res.push(`${Math.abs(this.seconds)}S`);
      }
    }

    return res.join('');
  }

  /**
   * Returns a readable interpration of the current time interval
   * @returns {string}
   */
  toReadableString() {
    return this.toISOString();
  }

  /**
   * Returns an object literal supporting add operation with moment date
   */
  toObjectLiteral() {
    return {
      years: this.years,
      months: this.months,
      weeks: this.weeks,
      days: this.days,
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
      milliseconds: this.millis_,
    };
  }
}
