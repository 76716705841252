import { Map } from 'immutable';
import { SUPPORTED_TIMEZONES } from './structs/timezone';
import { DEFAULT_DATETIME, DEFAULT_INTERVAL } from '../structs/options';
import {
  fitExtentToTimeRange,
  subtractIntervalFromDateTime,
} from '../core/utils';
import {
  MODULE_CHART_UPDATE_CACHEKEY,
  MODULE_CHART_UPDATE_STORAGE,
  MODULE_CHART_UPDATE_TIMEEXTENT,
  MODULE_CHART_UPDATE_TIMEZONE,
  MODULE_CHART_OVERVIEW_UPDATE_STORAGE,
} from './actions';
import { createTimeExtentObj } from './structs/utils';
import BatchDownloadReducer from './views/ViewBatchDownload/reducer';

// Define initial parameters
const DEFAULT_CHART_TIMEEXTENT = createTimeExtentObj(
  subtractIntervalFromDateTime(DEFAULT_DATETIME, DEFAULT_INTERVAL),
  0,
  'INIT',
);
const DEFAULT_OVERVIEW_TIMERANGE = subtractIntervalFromDateTime(DEFAULT_DATETIME, DEFAULT_INTERVAL);

// create inital state
const initialState = {
  // Can be used for resetting the chart data
  cacheKey: (new Date()).getTime(),
  // dynamic parameters (are changed to state changes)
  chartsStorage: new Map(),
  // the current displayed time extent
  chartsTimeExtent: DEFAULT_CHART_TIMEEXTENT,
  // TimeZone in which the current selected data is displayed. Default is Browser time
  chartsTimeZone: SUPPORTED_TIMEZONES.BROWSER,
  // timeRange of the OverviewChart. Make sure the timeRange of the OverviewChart
  // is always bigger than the timeExtent.
  // make the sure the timeRangeOverview is always bigger then the extent
  overviewTimeRange: DEFAULT_OVERVIEW_TIMERANGE,
  overviewChartsStorage: new Map(),
};

const chartReducer = function (state = initialState, action) {
  switch (action.type) {
    case MODULE_CHART_UPDATE_CACHEKEY: {
      return Object.assign({}, state, {
        cacheKey: action.cacheKey,
      });
    }

    case MODULE_CHART_UPDATE_STORAGE: {
      return Object.assign({}, state, {
        chartsStorage: action.chartsStorage,
        // through using #fitExtentToTimeRange() we make sure, that the propagated
        // timeExtent does always fit with the overviewTimeRange
        chartsTimeExtent: fitExtentToTimeRange(
          action.overviewTimeRange,
          action.chartsTimeExtent,
        ),
        overviewTimeRange: action.overviewTimeRange,
      });
    }

    case MODULE_CHART_UPDATE_TIMEEXTENT: {
      const chartsTimeExtent = createTimeExtentObj(
        action.timeExtent,
        action.componentId,
        action.eventSource,
      );

      return Object.assign({}, state, { chartsTimeExtent: chartsTimeExtent });
    }

    case MODULE_CHART_UPDATE_TIMEZONE: {
      return Object.assign({}, state, {
        chartsTimeZone: action.timeZone,
      });
    }

    case MODULE_CHART_OVERVIEW_UPDATE_STORAGE: {
      return Object.assign({}, state, {
        overviewChartsStorage: action.overviewChartsStorage,
      });
    }

    default: {
      return state;
    }
  }
};

function combinedReducers(state, action) {
  return {
    ...chartReducer(state, action),
    batchDownload: BatchDownloadReducer(state !== undefined ? state.batchDownload : undefined, action),
  };
}

export default combinedReducers;
