/**
 * @type {{NEAREST: number, LINEAR: number, CONST: number}}
 */
export const INTERPOLATOR_TYPE = {
  NEAREST: 0,
  LINEAR: 1,
  CONST: 2,
};

/**
 * Returns for a given set of interpolator settings a query param.
 * @param {{ type: number, value: * }}
 * @returns {string}
 */
export function encodeInterpolator({ type, value }) {
  return type === INTERPOLATOR_TYPE.CONST
    ? `CONST(${value})`
    : type === INTERPOLATOR_TYPE.NEAREST
      ? 'NEAREST_NEIGHBOUR'
      : 'LINEAR';
}

export default INTERPOLATOR_TYPE;
