/**
 * Created by jacob.mendt@pikobytes.de on 15.10.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

/**
 * AGGREGATION_TYPES which are currently supported.
 * @type {{MEAN: string, SUM: string}}
 */
export const AGGREGATION_TYPES = {
  MEAN: 'MEAN',
  SUM: 'SUM',
};
