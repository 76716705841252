/**
 * Created by nicolas.looschen@pikobytes.de on 05.02.2020.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CookieBanner from './CookieBanner';
import { disableGA, enableGA, initializeTracker, isGaCookieSet, isGADisabled } from '../../pages/PrivacyPage/PrivacyPage';

export default class CookieBannerContainer extends Component {
  constructor(props) {
    super(props);

    const isCookieSet = isGaCookieSet();

    // initialize google analytics only if a cookie is set and the consent is explicitly given
    if (isCookieSet && !isGADisabled()) {
      initializeTracker();
    }

    this.state = {
      showCookieBanner: !isCookieSet,
    };
  }


  /**
   * handles an accept click -> set activation cookie and initialize the tracker
   */
  handleAcceptClick = () => {
    enableGA();
    initializeTracker();
    this.hideBanner();
  };

  /**
   * handles a decline click -> set cookie to disable google analytics
   */
  handleDeclineClick = () => {
    disableGA();
    this.hideBanner();
  };

  /**
   * handles a click on the "show detail" button -> redirects to supplied detailPage
   */
  handleDetailClick = () => {
    const { detailPage, history } = this.props;
    history.push(detailPage);
  };

  /**
   * hides the cookie banner
   */
  hideBanner = () => { this.setState({ showCookieBanner: false }); };

  render() {
    const { showCookieBanner } = this.state;

    return showCookieBanner && <CookieBanner
      handleAcceptClick={this.handleAcceptClick}
      handleDeclineClick={this.handleDeclineClick}
      handleDetailClick={this.handleDetailClick}/>;
  }
}

CookieBannerContainer.propTypes = {
  detailPage: PropTypes.string,
  history: PropTypes.object,
};
