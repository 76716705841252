import moment from 'moment';
import { detect } from 'detect-browser';
import { BrowserClient, Hub } from '@sentry/browser';
import { on } from 'dom-helpers/events';
import isNil from 'lodash.isnil';
import PackageJson from '../package.json';
import { DEBUG } from './options';

// define globals
const APP_NAME = process.env.REACT_APP_NAME;
const LOGGING_API = process.env.REACT_APP_OSW_LOGGING_API;

// Initialize the sentry browser client but with the pikobytes backend
const client = new BrowserClient({
  dsn: LOGGING_API,
});

// We use the hub as our logger
export const Logger = new Hub(client);

// set basic information
Logger.configureScope((scope) => {
  if (!isNil(detect())) {
    const { name, version } = detect();
    scope.setTag('app-name', APP_NAME)
    scope.setTag('browser', name);
    scope.setTag('browser-version', version);
    scope.setTag('environment', DEBUG ? 'developer' : 'production');
    scope.setTag('sessionId', (new Date()).getTime());
    scope.setTag('sessionStart', (moment()).toISOString());
    scope.setTag('loggingVersion', PackageJson.version);
    scope.setLevel(DEBUG ? 'info' : 'warning');
  }
});

// add a listener for signaling the end of session
on(window, 'beforeunload', () => {
  Logger.configureScope((scope) => {
    scope.setTag('sessionEnd', (moment()).toISOString());
  });

  // send data to backend only if we are no in developing mode
  if (!DEBUG) {
    Logger.captureMessage('session_finish');
  }
});

export default Logger;
